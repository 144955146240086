import { html, component } from '../../_snowpack/pkg/haunted.js';

function ScribblHamburger({
  active: _active
}) {
  const active = Boolean(_active);
  return html`
    <style>
      :host {
        box-sizing: border-box;
        display: inline-block;
      }
      .hamburger {
        margin-left: -14px;
        box-sizing: border-box;
        z-index: 11;
        top: 0;
        position: fixed;
        padding: var(--hamburger-padding-y, 13px)
          var(--hamburger-padding-x, 15px);
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        overflow: visible;
        user-select: none;
      }
      .hamburger:hover {
        opacity: 0.7;
      }
      .hamburger.is-active:hover {
        opacity: 0.7;
      }
      .hamburger.is-active .hamburger-inner,
      .hamburger.is-active .hamburger-inner::before,
      .hamburger.is-active .hamburger-inner::after {
        background-color: var(
          --hamburger-active-color,
          var(--sc-colors-gray400)
        );
      }
      .hamburger-box {
        box-sizing: border-box;
        width: var(--hamburger-width, 24px);
        height: var(--hamburger-height, 24px);
        display: inline-block;
        position: relative;
        user-select: none;
      }
      .hamburger-inner {
        background-color: var(--hamburger-color-line-middle, #965a3e);
        display: block;
        top: 50%;
        margin-top: 0px;
        user-select: none;
      }
      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        box-sizing: border-box;
        width: var(--hamburger-width, 24px);
        height: var(--hamburger-line-size, 4px);
        border-radius: var(--hamburger-line-size, 4px);
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }
      .hamburger-inner::before,
      .hamburger-inner::after {
        content: '';
        display: block;
      }
      .hamburger-inner::before {
        background-color: var(--hamburger-color-line-top, sandybrown);
        top: var(--hamburger-line-separation, -7px);
      }
      .hamburger-inner::after {
        background-color: var(--hamburger-color-line-bottom, sandybrown);
        bottom: var(--hamburger-line-separation, -7px);
      }
      .hamburger--squeeze .hamburger-inner {
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      .hamburger--squeeze .hamburger-inner::before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }
      .hamburger--squeeze .hamburger-inner::after {
        transition: bottom 0.075s 0.12s ease,
          transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      .hamburger--squeeze.is-active .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      .hamburger--squeeze.is-active .hamburger-inner::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      }
      .hamburger--squeeze.is-active .hamburger-inner::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
          transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      button:focus {
        outline: none;
      }
    </style>

    <button
      class="hamburger hamburger--squeeze ${active ? 'is-active' : ''}"
      type="button"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  `;
}

customElements.define('scribbl-hamburger', component(ScribblHamburger, {
  observedAttributes: ['active']
}));