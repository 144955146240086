import { html, component, createContext, useCallback, useContext, useState } from '../../_snowpack/pkg/haunted.js';
const DrawerContext = createContext({});
customElements.define('drawer-context', DrawerContext.Provider);
export const useDrawerProvider = () => {
  const [isOpen, setDrawer] = useState(false);
  const setOpen = useCallback(() => {
    setDrawer(true);
  }, [setDrawer]);
  const setClosed = useCallback(() => {
    setDrawer(false);
  }, [setDrawer]);
  return {
    isOpen,
    setOpen,
    setClosed
  };
};
export const useDrawer = () => useContext(DrawerContext);

const DrawerProvider = () => {
  const ctx = useDrawerProvider();
  return html` <drawer-context .value=${ctx}><slot></slot></drawer-context> `;
};

customElements.define('drawer-provider', component(DrawerProvider));