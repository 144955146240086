import { html, component } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/LogoutIcon.js';
import { useAuth } from '../hooks/data.js';
import { useDrawer } from '../hooks/drawer.js';
import './scribbl-hamburger.js';
import './scribbl-text-logo.js';
import './scribbl-button.js';
import './scribbl-create-artboard.js';
import './scribbl-delete-sheet.js';

function ScribblHeader({
  drawer = false
}) {
  const {
    user,
    logout
  } = useAuth();
  const {
    isOpen: drawerOpen,
    setClosed: closeDrawer,
    setOpen: openDrawer
  } = useDrawer();
  return html`
    <style>
      header {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem 0.75rem;
        height: 56px;
        background: var(--header-bg-color, var(--sc-colors-gray50));
        border-bottom: 1px solid
          var(--header-border-color, var(--sc-colors-gray200));
      }
      .group {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      logout-icon {
        --i-icon-height: 20px;
        --i-icon-width: 20px;
        --i-icon-color: var(--sc-colors-gray-900);
      }
      .icon-btn {
        gap: 0.25rem;
      }
      @media (max-width: 420px) {
        button > span {
          display: none;
        }
        button {
          width: 32px;
        }
      }
    </style>
    ${user ? html`<header>
          ${drawer ? html`<scribbl-hamburger
                @click=${drawerOpen ? closeDrawer : openDrawer}
                ?active=${drawerOpen}
              ></scribbl-hamburger>` : html`<scribbl-text-logo></scribbl-text-logo>`}
          <slot></slot>
          <div class="group">
            <scribbl-create-artboard></scribbl-create-artboard>
            ${drawer ? html`<scribbl-delete-sheet></scribbl-delete-sheet>` : ''}
            <scribbl-button size="sm">
              <button @click=${logout} class="icon-btn">
                <logout-icon></logout-icon>
                <span>Log out</span>
              </button>
            </scribbl-button>
          </div>
        </header>` : ''}
  `;
}

customElements.define('scribbl-header', component(ScribblHeader, {
  observedAttributes: ['drawer']
}));