import { html, component } from '../../_snowpack/pkg/haunted.js';
import { ToolLabels } from '../hooks/tool-picker.js';
import './scribbl-artboard-tool.js';

function ScribblSelectedTool({
  tool
}) {
  return html`
    <style>
      :host {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      scribbl-artboard-tool {
        --tool-width: 20px;
        --tool-height: 20px;
      }
      span {
        font-size: 14px;
      }
    </style>
    <span>${ToolLabels[tool]}</span>
    <scribbl-artboard-tool
      tool=${tool}
      ?disabled=${true}
    ></scribbl-artboard-tool>
  `;
}

customElements.define('scribbl-selected-tool', component(ScribblSelectedTool, {
  observedAttributes: ['tool']
}));