import { html, component, useCallback, useRef, useEffect } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/CheckIcon.js';
import { forwardRef } from '../utils/index.js';
const SWATCH_EVENT_SELECTED = 'swatch-selected';

function ScribblColorSwatch({
  color,
  selected: _selected,
  disabled: _disabled
}) {
  const ref = useRef(null);
  const selected = Boolean(_selected);
  const disabled = Boolean(_disabled);
  const onSelect = useCallback(() => {
    if (!disabled) {
      // @ts-ignore
      const _swatch = this;

      _swatch.dispatchEvent(new CustomEvent(SWATCH_EVENT_SELECTED, {
        bubbles: true,
        composed: true,
        detail: {
          color
        }
      }));
    }
  }, [disabled, color]);
  useEffect(() => {
    if (disabled || !selected) return;

    if (ref.current) {
      if ('scrollIntoView' in ref.current) {
        ref.current.scrollIntoView({
          block: 'center'
        });
        return;
      }

      if ('scrollIntoViewIfNeeded' in ref.current) {
        ref.current.scrollIntoViewIfNeeded(true);
      }
    }
  }, [disabled, selected]);
  return html`
    <style>
      .swatch {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        cursor: pointer;
        background-color: ${color};
        width: var(--color-swatch-width, 52px);
        height: var(--color-swatch-height, 52px);
        border-radius: var(--color-swatch-border-radius, 0.25rem);
      }
      check-icon {
        --i-icon-width: 18px;
        --i-icon-height: 18px;
        border-radius: 9999px;
        background-color: white;
        color: green;
        padding: 0.25rem;
        margin: 0.15rem;
      }
    </style>
    <div class="swatch" @click=${onSelect} ref=${forwardRef(ref)}>
      ${selected ? html`<check-icon></check-icon>` : ''}
    </div>
  `;
}

customElements.define('scribbl-color-swatch', component(ScribblColorSwatch, {
  observedAttributes: ['color', 'selected', 'disabled']
}));