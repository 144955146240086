import { html, component } from '../../_snowpack/pkg/haunted.js';

function ScribblLogo() {
  return html`
    <style>
      :host {
        box-sizing: border-box;
        display: block;
        height: var(--logo-height, 180px);
        width: auto;
      }
      svg {
        height: 100%;
        width: auto;
      }
    </style>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 166">
      <path
        d="M86.536 6c1.54-2.667 5.388-2.667 6.928 0l83.072 143.885c1.539 2.666-.385 6-3.464 6H6.928c-3.079 0-5.003-3.334-3.464-6L86.536 6z"
        fill="#fff"
      />
      <path
        d="M144.254 104.687l26.47 45.849h-52.941l26.471-45.849z"
        fill="#F4A460"
      />
      <path d="M90 104.687l26.471 45.849H63.529L90 104.687z" fill="#8218FB" />
      <path d="M90 12.226l26.471 45.849H63.529L90 12.226z" fill="#3A1169" />
      <path
        d="M35.746 104.687l26.47 45.849H9.277l26.47-45.849z"
        fill="#FD3C78"
      />
    </svg>
  `;
}

customElements.define('scribbl-logo', component(ScribblLogo));