import { b as component } from '../../../common/lit-haunted-f8d3dca7.js';
import { i as iconStyle } from '../../../common/icon.esm-2747b86a.js';
import { h as html } from '../../../common/lit-html-3386f0db.js';

const style = iconStyle("outline");

function HandIcon() {
  return html`${style}<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"/>
</svg>
`;
}

customElements.define("hand-icon", component(HandIcon));
