import { html, component, useCallback, useRef } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/ShareIcon.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/ClipboardCopyIcon.js';
import './scribbl-button.js';
import { forwardRef } from '../utils/index.js';
const canShare = !!navigator.share;

const share = (title, url) => {
  navigator.share({
    title,
    url
  }).then(() => console.log('Successful share')).catch(error => console.log('Error sharing', error));
};

function ScribblSheetShare({
  shareId,
  title
}) {
  const ref = useRef(null);
  const url = `${window.location.origin}/s/${shareId}`;
  const action = useCallback(e => {
    e.stopPropagation();

    if (canShare) {
      share(title, url);
    } else {
      ref.current.select();
      document.execCommand('copy');
    }
  }, [url]);
  return html`
    <style>
      :host {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      button {
        padding: 0;
        width: 32px;
        height: 32px;
      }
      clipboard-copy-icon,
      share-icon {
        --i-icon-width: 20px;
        --i-icon-height: 20px;
      }
      span {
        text-transform: uppercase;
        font-size: 14px;
      }
      input {
        position: absolute;
        height: 1px;
        width: 1px;
        opacity: 0;
      }
    </style>
    <scribbl-button>
      <button @click=${action}>
        ${canShare ? html`<share-icon></share-icon>` : html`<clipboard-copy-icon></clipboard-copy-icon>`}
      </button>
    </scribbl-button>
    <input ref=${forwardRef(ref)} type="text" .value=${url} />
  `;
}

customElements.define('scribbl-sheet-share', component(ScribblSheetShare, {
  observedAttributes: ['title', 'shareId']
}));