import { nanoid } from '../../_snowpack/pkg/nanoid.js';
import { debug } from './index.js';
export let CanvasLineJoin;

(function (CanvasLineJoin) {
  CanvasLineJoin[CanvasLineJoin["Round"] = 0] = "Round";
})(CanvasLineJoin || (CanvasLineJoin = {}));

export let CanvasLineCap;

(function (CanvasLineCap) {
  CanvasLineCap[CanvasLineCap["Round"] = 0] = "Round";
})(CanvasLineCap || (CanvasLineCap = {}));

export let CanvasOperationType;

(function (CanvasOperationType) {
  CanvasOperationType[CanvasOperationType["Stroke"] = 0] = "Stroke";
  CanvasOperationType[CanvasOperationType["Rect"] = 1] = "Rect";
})(CanvasOperationType || (CanvasOperationType = {}));

export let CanvasStrokeOperationType;

(function (CanvasStrokeOperationType) {
  CanvasStrokeOperationType[CanvasStrokeOperationType["Add"] = 0] = "Add";
  CanvasStrokeOperationType[CanvasStrokeOperationType["Remove"] = 1] = "Remove";
})(CanvasStrokeOperationType || (CanvasStrokeOperationType = {}));

const convertToColorString = color => {
  if (typeof color === 'string') {
    return color;
  }

  if (color === 0) {
    return '#000000';
  }

  return '#' + color.toString(16).padStart(6, '0');
};

const convertToColorNumber = color => {
  if (typeof color === 'number') {
    return color;
  }

  return parseInt(color.replace('#', ''), 16);
};

export const strokeOperations = ['source-over', 'destination-out'];
export const lineJoin = ['round'];
export const lineCap = ['round'];
export class CanvasDocument {
  constructor(width, height, cursor = 0, size = 0, createdAt = Date.now(), operations = [], updatedAt, id) {
    this._id = id || nanoid();
    this._width = width;
    this._height = height;
    this._operations = operations;
    this._cursor = cursor;
    this._size = size;
    this._createdAt = createdAt;
    this._updatedAt = updatedAt || this._createdAt;
    this._committedAt = -1;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  update() {
    this._updatedAt = Date.now();
  }

  renew() {
    this.update(); // if (this._committedAt === -1 || this._committedAt >= this._createdAt) {
    //   this._createdAt = Date.now();
    // }
  }

  import(doc) {
    this._width = doc._width;
    this._height = doc._height;
    this._size = doc._size;
    this._operations = doc._operations; // start from the beginning to allow it to load and save from beginning of operations

    this._cursor = 0;
    this._createdAt = doc.createdAt;
    this._updatedAt = doc.updatedAt;
    this._committedAt = -1;
  }
  /**
   * Record line
   */


  line(mx, my, px, py, strokeType, color, width, join, cap) {
    this.renew();

    this._operations.push({
      op: CanvasOperationType.Stroke,
      ot: strokeOperations.indexOf(strokeType),
      mx,
      my,
      px,
      py,
      cc: color,
      lw: width,
      lj: lineJoin.indexOf(join),
      lc: lineCap.indexOf(cap)
    });

    this._size++;
  }
  /**
   * Record rect
   */


  rect(px, py, width, height, color) {
    this.renew();

    this._operations.push({
      op: CanvasOperationType.Rect,
      px,
      py,
      rw: width,
      rh: height,
      cc: color
    });

    this._size++;
  }
  /**
   * Copies document to new instance, optionally slicing operations range
   */


  copy(start, end) {
    const origLength = this._size;
    const size = Math.max((end || origLength) - (start || 0), 0) || origLength;
    const diff = origLength - size;
    return new CanvasDocument(this._width, this._height, Math.max(this._cursor - diff, 0), size, this._createdAt, this._operations.slice(start, end), undefined, this._id);
  }

  setCursor(val) {
    this._cursor = val;
  }

  advanceCursor(amount = -1) {
    // move to the ends by default
    if (amount === -1) {
      this.setCursor(this._size - 1);
    } else {
      this.setCursor(this._cursor + amount);
    }
  }

  rewindCursor(amount = -1) {
    // move to the ends by default
    if (amount === -1) {
      this.setCursor(0);
    } else {
      this.setCursor(this._cursor - amount);
    }
  }
  /**
   * Reads contiguous operations, yielding to the caller between changes in operation streams
   */


  *read(fromCursor = -1) {
    let end = this._size;
    let start = fromCursor === -1 ? this._cursor : fromCursor;
    let prev = null;
    let contiguous = [];

    while (start < end) {
      const op = this._operations[start];

      if (contiguous.length && (prev === null || prev?.op !== op?.op || prev?.cc !== op?.cc || prev?.ot !== op?.ot || prev?.lw !== op?.lw)) {
        yield contiguous;
        contiguous = [];
      }

      if (op) {
        prev = op;
        contiguous.push(op);
      }

      this.setCursor(start);
      start++;
    }

    if (contiguous.length) {
      yield contiguous;
    }
  }

  serializeOperations() {
    let e = this._size;
    let out = '';

    for (let i = 0; i < e; i++) {
      const op = this._operations[i];

      if (op.ot !== undefined) {
        out = (out ? out + ';' : '') + `${op.op}:${op.ot}:${op.lw}:${op.lj}:${op.lc}:${convertToColorNumber(op.cc)}:${op.mx}:${op.my}:${op.px}:${op.py}`;
      } else {
        out = (out ? out + ';' : '') + `${op.op}:${convertToColorNumber(op.cc)}:${op.px}:${op.py}:${op.rw}:${op.rh}`;
      }
    }

    return out;
  }

  deserializeOperations(_operations) {
    if (!_operations) return;
    let e = this._size;

    const ops = _operations.split(';');

    this._operations = new Array(e);

    for (let i = 0; i < e; i++) {
      const _op = ops[i];

      const fields = _op.split(':');

      if (parseInt(fields[0]) === CanvasOperationType.Stroke) {
        const [op, ot, lw, lj, lc, cc, mx, my, px, py] = fields;
        this._operations[i] = {
          op: parseInt(op),
          ot: parseInt(ot),
          lw: parseInt(lw),
          lj: parseInt(lj),
          lc: parseInt(lc),
          cc: convertToColorString(parseInt(cc)),
          mx: parseInt(mx),
          my: parseInt(my),
          px: parseInt(px),
          py: parseInt(py)
        };
      } else {
        const [op, cc, px, py, rw, rh] = fields;
        this._operations[i] = {
          op: parseInt(op),
          cc: convertToColorString(parseInt(cc)),
          px: parseInt(px),
          py: parseInt(py),
          rw: parseInt(rw),
          rh: parseInt(rh)
        };
      }
    }
  }
  /**
   * Returns the serialized format of a CanvasDocument, for persisting to disk
   */


  serialize() {
    return {
      di: this._id,
      dw: this._width,
      dh: this._height,
      cu: this._cursor,
      ca: this._createdAt,
      op: this.serializeOperations(),
      sz: this._size
    };
  }

  shouldRender(_doc) {
    debug({
      this: this._id,
      inc: _doc.di
    });
    return _doc.di !== this._id;
  } // Merges another document with this one, adjusting the operations and size


  deserializeAndMerge(_doc) {
    // We want to avoid processing the same data if it comes through on realtime updates
    if (!this.shouldRender(_doc)) return;
    const doc = this.copy();
    doc.deserialize(_doc);

    this._operations.push(...doc._operations);

    this._size = this._size + doc._size;
    this._width = doc._width;
    this._height = doc._height;
    this._updatedAt = doc._createdAt;
    debug({
      doc,
      this: this
    });
  }
  /**
   * Takes a serialized document format and deserializes it to the current instance, and returns itself
   */


  deserialize(doc) {
    this._width = doc.dw;
    this._height = doc.dh;
    this._cursor = doc.cu;
    this._size = doc.sz;
    this._createdAt = doc.ca;
    this.deserializeOperations(doc.op || '');
    return this;
  }

  empty() {
    return this._size < 1;
  }

  snapshot(dt) {
    this._createdAt = dt;
  }
  /**
   * Commit operations to persist document snapshot
   */


  commit(recentOnly = true) {
    this.update();
    const doc = this.copy(recentOnly ? this._cursor : undefined); // snapshot the segment

    doc.snapshot(this._updatedAt); // Move the cursor to the end of the current operations

    this.advanceCursor(); // mark commited time

    this._committedAt = Date.now(); // return the serialized snapshot

    return doc.serialize();
  }

}