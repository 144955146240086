import { useState, useCallback } from '../../_snowpack/pkg/haunted.js';
var EmptyDrawerIdentifier;

(function (EmptyDrawerIdentifier) {})(EmptyDrawerIdentifier || (EmptyDrawerIdentifier = {}));

export const useDrawerSection = (sections = {}) => {
  const [state, setState] = useState(() => {
    return Object.values(sections).reduce((s, k) => ({ ...s,
      [k]: typeof sections[k] === 'boolean' ? sections[k] : true // Collapsed by default

    }), {});
  });
  const toggle = useCallback(e => {
    const {
      label,
      collapsed
    } = e.detail;
    setState(prev => ({ ...prev,
      [label]: !collapsed
    }));
  }, [setState]);
  return [state, toggle];
};