import { component, html, useEffect, useState } from '../../_snowpack/pkg/haunted.js';
import { useRoute } from '../../_snowpack/pkg/@martel/haunted-router.js';
import { decode } from '../../_snowpack/pkg/js-base64.js';
import './scribbl-text-logo.js';
import './scribbl-header.js';
import './scribbl-button.js';
import './scribbl-image.js';
import { db, useAuth } from '../hooks/data.js';

const useSheetView = sheetId => {
  const [sheet, setSheet] = useState(null);
  useEffect(() => {
    if (sheetId) {
      const [userId, ...path] = decode(sheetId).split('/');
      const user = db.get(userId);
      path.reduce((d, p) => d.get(p), user).once(data => {
        if (!data) return;
        setSheet(data);
      });
    }
  }, [sheetId]);
  return {
    sheet
  };
};

function ScribblSheetView({}) {
  const route = useRoute();
  const {
    user,
    logout
  } = useAuth();
  const {
    sheet
  } = useSheetView(route.params?.sheetId);
  return html`
    <style>
      :host {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
      }
      header {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem 0.75rem;
        height: 56px;
        background: var(--header-bg-color, var(--sc-colors-gray50));
        border-bottom: 1px solid
          var(--header-border-color, var(--sc-colors-gray200));
      }
      .group {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      main {
        flex: 1;
        display: flex;
        width: 100%;
      }
    </style>
    <header>
      <scribbl-link href="/">
        <scribbl-text-logo></scribbl-text-logo>
      </scribbl-link>
      <h1>${sheet?.title}</h1>
      <div class="group">
        ${user ? html`<scribbl-button size="sm">
              <button @click=${logout}>Log out</button>
            </scribbl-button>` : ''}
      </div>
    </header>
    <main>
      <scribbl-image .src=${sheet?.preview}></scribbl-image>
    </main>
  `;
}

customElements.define('scribbl-sheet-view', component(ScribblSheetView));