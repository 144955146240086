import { html, component, useCallback, useState, useRef, useEffect } from '../../_snowpack/pkg/haunted.js';
import { forwardRef } from '../utils/index.js';
import { useAuth } from '../hooks/data.js';
import './scribbl-logo.js';
import './scribbl-text-logo.js';
import './scribbl-button.js';

function ScribblSession({}) {
  const {
    register,
    login
  } = useAuth();
  const focusRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [isRegister, setIsRegister] = useState(false);
  useEffect(() => {
    focusRef.current?.focus();
  }, []);
  const submit = useCallback(async e => {
    e.preventDefault();
    setErrors({});
    const form = new FormData(e.target);
    const email = form.get('email');
    const password = form.get('password');
    const passwordConfirm = form.get('passwordConfirm');

    if (isRegister) {
      if (password !== passwordConfirm) {
        setErrors({
          passwordConfirm: 'Confirmation must match password'
        });
        return;
      }

      const res = await register(email, password);

      if (res?.err) {
        setErrors({
          _: res.err
        });
      }
    } else {
      const res = await login(email, password);

      if (res?.err) {
        setErrors({
          _: res.err
        });
      }
    }
  }, [isRegister]);
  const toggleRegister = useCallback(e => {
    e.preventDefault();
    setIsRegister(prev => !prev);
  }, [setIsRegister]);
  return html`
    <style>
      :host {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 0.5rem;
      }
      form {
        box-sizing: border-box;
        width: 100%;
        max-width: 400px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
      input:not([type='checkbox']) {
        transition: all 150ms ease-out;
        border: 1px solid var(--sc-colors-gray300);
        border-radius: 0.25rem;
        font-size: 0.875rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        margin-top: 0.125rem;
        padding: 0.5rem 0.75rem;
      }
      input:not([type='checkbox']):hover {
        border-color: var(--sc-colors-primary500);
      }
      scribbl-button {
        margin-top: 1.5rem;
      }
      label {
        box-sizing: border-box;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        box-sizing: border-box;
        display: flex;
        gap: 0.25rem;
      }
      label:not(.stack) {
        align-items: center;
      }
      .stack {
        box-sizing: border-box;
        flex-direction: column;
      }
      .error {
        box-sizing: border-box;
        padding: 0;
        margin: 0.25rem 0;
        font-size: 14px;
        font-weight: 400;
        text-transform: initial;
        color: red;
      }
      scribbl-logo {
        --logo-height: 48px;
        flex-shrink: 1;
      }
      scribbl-text-logo {
        --logo-height: 44px;
      }
      .logo {
        display: flex;
        gap: 0.75rem;
        margin: 0 auto 2rem;
      }
      *:focus {
        outline: none;
        box-shadow: 0 0 0 2px var(--sc-colors-primary200);
      }
    </style>
    <form id="session-form" @submit=${submit}>
      <div class="logo">
        <scribbl-logo></scribbl-logo>
        <scribbl-text-logo></scribbl-text-logo>
      </div>
      ${errors._ ? html`<p class="error">${errors._}</p>` : ''}
      <label class="stack">
        Email
        <input
          type="email"
          id="email"
          name="email"
          ref=${forwardRef(focusRef)}
        />
        ${errors.email ? html`<p class="error">${errors.email}</p>` : ''}
      </label>
      <label class="stack">
        Password
        <input type="password" id="password" name="password" />
        ${errors.password ? html`<p class="error">${errors.password}</p>` : ''}
      </label>
      ${isRegister ? html` <label class="stack">
            Confirm Password
            <input
              type="password"
              id="passwordConfirm"
              name="passwordConfirm"
            />
            ${errors.passwordConfirm ? html`<p class="error">${errors.passwordConfirm}</p>` : ''}
          </label>` : ''}

      <label>
        <input
          type="checkbox"
          id="register"
          name="register"
          @change=${toggleRegister}
        />
        Register new account
      </label>

      <scribbl-button variant="primary" width="100%">
        <button type="submit">${isRegister ? 'Sign up' : 'Log in'}</button>
      </scribbl-button>
    </form>
  `;
}

customElements.define('scribbl-session', component(ScribblSession));