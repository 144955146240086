import { c as createCommonjsModule, a as commonjsGlobal } from '../../common/_commonjsHelpers-4f955397.js';

var updateLocale = createCommonjsModule(function (module, exports) {
!function (e, n) {
   module.exports = n() ;
}(commonjsGlobal, function () {

  return function (e, n, t) {
    t.updateLocale = function (e, n) {
      var o = t.Ls[e];
      if (o) return (n ? Object.keys(n) : []).forEach(function (e) {
        o[e] = n[e];
      }), o;
    };
  };
});
});

export default updateLocale;
