import { html, component, useMemo } from '../../_snowpack/pkg/haunted.js';
import { repeat } from '../../_snowpack/pkg/lit-html/directives/repeat.js';
import { date } from '../utils/index.js';

function getLink(id) {
  return id.split('/').pop();
}

function ScribblSheets({
  artboardId,
  sheetId: _sheetId,
  sheets
}) {
  const sheetId = useMemo(() => getLink(_sheetId), [_sheetId]);
  return html`
    <style>
      :host {
        box-sizing: border-box;
        width: var(--viewport-width, 100%);
        display: flex;
        flex-direction: column;
      }
      .sheets {
        z-index: 0;
        box-sizing: border-box;
        width: var(--viewport-width, 100%);
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
      .sheet {
        transition: background-color 150ms ease-out, color 150ms ease-out;
        color: var(--sc-colors-gray600);
        box-sizing: border-box;
        display: flex;
        gap: 0.25rem;
        padding: 1rem 0.75rem;
      }
      .empty {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0.75rem;
      }
      .is-selected {
        background: var(--sc-colors-gray100);
        color: inherit;
      }
      scribbl-link {
        flex: 1 1 auto;
        min-width: 0;
      }
      scribbl-link::part(link) {
        font-size: 0.75rem;
        display: block;
        min-width: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: none;
        transition: color 150ms ease-out;
        color: inherit;
      }
      time {
        line-height: 1.5;
        font-size: 0.75rem;
        color: inherit;
        flex-shrink: 0;
      }
    </style>
    <div class="sheets">
      ${sheets ? repeat(sheets, sheet => sheet.id, v => html`
                <div class="sheet${sheetId === v.id ? ' is-selected' : ''}">
                  <scribbl-link
                    href="/artboards/${artboardId}?v=${getLink(v.id)}"
                  >
                    ${v.title}
                  </scribbl-link>
                  <time datetime=${v.updatedAt}
                    >${date(v.updatedAt).fromNow()}</time
                  >
                </div>
              `) : html` <div class="empty">No sheets created</div> `}
    </div>
  `;
}

customElements.define('scribbl-sheets', component(ScribblSheets, {
  observedAttributes: ['artboardId', 'sheetId', 'sheets']
}));