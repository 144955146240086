import { createContext, useContext, useState, useEffect, useCallback } from '../../../_snowpack/pkg/haunted.js';
import { _user } from '../data.js';
const ViewArtboardContext = createContext({});
customElements.define('view-artboard-context', ViewArtboardContext.Provider);
export const useViewArtboardProvider = ({
  id: artboardId,
  v
}) => {
  const [artboard, setArtboard] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [sheet, setSheet] = useState(null);
  const getSheetRef = useCallback(() => {
    return _user.get(`artboards`).get(artboardId).get(`sheets`).get(v);
  }, [artboardId, v]);
  useEffect(() => {
    setSheets([]);
  }, [artboardId, setSheets]);
  useEffect(() => {
    if (!artboardId) return;
    let ev = null;

    _user.get(`artboards`).get(artboardId) // @ts-ignore
    .on((_ab, _k, _m, _e) => {
      ev = _e;
      if (!_ab) return;
      setArtboard({ ..._ab,
        id: artboardId
      });
    });

    return () => {
      ev?.off?.();
    };
  }, [artboardId, setArtboard]);
  useEffect(() => {
    if (!artboardId) return;
    let ev = [];

    _user.get(`artboards`).get(artboardId).get(`sheets`).map() // @ts-ignore
    .on((data, _vk, _m, _e) => {
      ev.push(_e);
      if (!data) return;
      const {
        id,
        title,
        createdAt,
        updatedAt
      } = data;
      setSheets(prev => {
        const idx = prev.findIndex(pv => pv.id === _vk);

        if (idx < 0) {
          return [{
            id: _vk,
            title,
            createdAt,
            updatedAt
          }, ...prev];
        }

        return [...prev.slice(0, idx), { ...prev[idx],
          title,
          createdAt,
          updatedAt
        }, ...prev.slice(idx + 1)];
      });
    });

    getSheetRef().once(data => {
      if (!data) return;
      const {
        title,
        createdAt,
        shareId = '',
        updatedAt,
        _
      } = data;
      setSheet(_v => ({ ..._v,
        id: !v ? _['#'] : v,
        shareId,
        title,
        createdAt,
        updatedAt
      }));
    });
    getSheetRef().get('shareId') // @ts-ignore
    .on((shareId, _vk, _m, _e) => {
      ev.push(_e);
      setSheet(_v => ({ ..._v,
        shareId
      }));
    });
    getSheetRef().get('data').map(data => {
      return data?.sz ? data : undefined;
    }).once(data => {
      if (!data) return;
      setSheet(_v => ({ ..._v,
        data
      }));
    });
    return () => {
      ev?.map(e => e?.off?.());
    };
  }, [artboardId, getSheetRef, setSheet, setSheets, v]);
  return {
    artboard,
    sheet,
    sheets,
    setSheets
  };
};
export const useViewArtboard = () => useContext(ViewArtboardContext);