import { b as component } from '../../../common/lit-haunted-f8d3dca7.js';
import { i as iconStyle } from '../../../common/icon.esm-2747b86a.js';
import { h as html } from '../../../common/lit-html-3386f0db.js';

const style = iconStyle("outline");

function PhotographIcon() {
  return html`${style}<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
</svg>
`;
}

customElements.define("photograph-icon", component(PhotographIcon));
