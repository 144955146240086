import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

const config = {
  production: {
    db: {
      peers: ['https://srtc.martel.app/gun'],
      localStorage: false
    }
  },
  development: {
    db: {
      peers: [],
      localStorage: false
    }
  }
}[__SNOWPACK_ENV__.MODE];
export const dbConfig = config.db;