import { html, component, useMemo, useCallback } from '../../_snowpack/pkg/haunted.js';
const DRAWER_EVENT_CLOSE_OUTSIDE = 'drawer-click-outside';
const DRAWER_EVENT_CLOSED = 'drawer-closed';

function preventClick(e) {
  e.stopPropagation();
}

function ScribblDrawer({
  direction: _direction,
  modal: _modal,
  opened: _opened
}) {
  const direction = _direction || 'left';
  const modal = Boolean(_modal);
  const opened = Boolean(_opened);
  const close = useCallback(() => {
    if (opened) {
      // @ts-ignore
      const _drawer = this;

      _drawer.dispatchEvent(new CustomEvent(DRAWER_EVENT_CLOSED, {
        bubbles: true,
        composed: true
      }));
    }
  }, [opened]);
  const documentClose = useCallback(() => {
    if (opened) {
      // @ts-ignore
      const _drawer = this;

      close();

      _drawer.dispatchEvent(new CustomEvent(DRAWER_EVENT_CLOSE_OUTSIDE, {
        bubbles: true,
        composed: true
      }));
    }
  }, [opened, close]);
  const modalStyles = useMemo(() => {
    if (opened) {
      return html`display: block; opacity: 1;`;
    }

    return '';
  }, [opened]);
  const menuStyles = useMemo(() => {
    if (direction === 'top') {
      return html`
        top: var(--drawer-closed-top, -100vh); left: var(--drawer-closed-left,
        0); height: var(--drawer-content-height, auto); width:
        var(--drawer-content-width, 100vw); border-bottom: 1px solid
        var(--sc-colors-gray200);
        ${opened ? html`transform: translateY(100vh); ` : ''}
      `;
    }

    return html`
      top: var(--drawer-closed-top, 0); left: var(--drawer-closed-left, -100vw);
      height: var(--drawer-content-height, 100vh); width:
      var(--drawer-content-width, auto); border-right: 1px solid
      var(--sc-colors-gray200);
      ${opened ? html` transform: translateX(100vw); ` : ''}
    `;
  }, [opened, direction]);
  return html`
    <style>
      :host {
        box-sizing: border-box;
        display: block;
      }
      .modal {
        box-sizing: border-box;
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: var(--drawer-modal-z-index, var(--drawer-z-index, 9999));
        opacity: 0;
        will-change: opacity;
        transition: opacity 100ms cubic-bezier(1,.6,.98,.29);
        background-color: var(
          --drawer-modal-background-color,
          rgba(255, 255, 255, 0.6)
        );
        ${modalStyles}
      }
      .menu {
        box-sizing: border-box;
        position: fixed;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        z-index: var(--drawer-z-index, 9999);
        background-color: var(--drawer-background-color, white);
        will-change: transform;
        transition: transform 100ms cubic-bezier(1,.6,.98,.29);
        ${menuStyles}
      }
      header {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: var(--drawer-header-height, 56px);
        padding-left: 54px;
      }
      section {
        box-sizing: border-box;
        height: 100%;
        flex: 1;
      }
    </style>
    ${modal ? html`<div class="modal" @click=${documentClose}></div>` : ''}
    <div class="menu" @click=${preventClick}>
      <header>
        <slot name="header"></slot>
      </header>
      <section>
        <slot></slot>
      </section>
    </div>
  `;
}

customElements.define('scribbl-drawer', component(ScribblDrawer, {
  observedAttributes: ['opened', 'modal', 'direction']
}));