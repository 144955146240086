import { h as html } from '../common/lit-html-3386f0db.js';
import { h as hook, H as Hook, u as useMemo, c as createContext, a as useContext, b as component } from '../common/lit-haunted-f8d3dca7.js';

let hookPath = new Map(),
    farthestPath = location.pathname;

function addCurrent(hook) {
  if (hookPath.has(hook)) return;
  let match = hook.matches(farthestPath);
  hookPath.set(hook, match);
  if (match) farthestPath = farthestPath.slice(match.length);
}

function removeCurrent(hook) {
  hookPath.delete(hook);
}

function update() {
  let match;
  farthestPath = location.pathname;

  for (let [hook, oldMatch] of hookPath.entries()) {
    match = hook.matches(farthestPath);

    if (oldMatch !== match) {
      hook.state.update();
      if (match === undefined) break;
      hookPath.set(hook, match);
    }

    if (match) farthestPath = farthestPath.slice(match.length);
  } // Update the last item of the hook path anyway


  const lastHook = [...hookPath.keys()].pop();

  if (lastHook) {
    lastHook.state.update();
  }
}

function navigateTo(url, state) {
  history.pushState(state, '', url);
  update();
}

function replaceTo(url, state) {
  history.replaceState(state, '', url);
  update();
}

window.addEventListener('popstate', update);

const paramMatcher = /:[a-zA-Z0-9]+/g;

function createRouteEntry([path, callback]) {
  let pattern = '^',
      lastIndex = 0,
      match;
  const {
    name: routeName,
    entry
  } = typeof callback === 'function' ? {
    name: undefined,
    entry: callback
  } : callback;
  const exact = path.slice(-1) != '*',
        names = [];

  while (match = paramMatcher.exec(path)) {
    const [name] = match;
    names.push(name.slice(1));
    pattern += path.slice(lastIndex, match.index) + '([^/]*)';
    lastIndex = match.index + name.length;
  }

  pattern += path.slice(lastIndex, exact ? undefined : -1);

  if (exact) {
    pattern += '$';
  }

  const regex = new RegExp(pattern);

  const matcher = path => {
    const match = regex.exec(path);
    if (!match) return [undefined, {}, false];
    const [string, ...values] = match;
    const params = names.reduce((obj, name, i) => ({ ...obj,
      [name]: values[i]
    }), {});
    return [string, params, exact];
  };

  return {
    matcher,
    callback: entry,
    name: routeName
  };
}

const useRoutes = hook(class extends Hook {
  fallback;
  _routes;
  _result;

  constructor(id, state, routes, fallback) {
    super(id, state);
    this.fallback = fallback;
    this._routes = Object.entries(routes).map(createRouteEntry);
  }

  update() {
    addCurrent(this);
    return this._result;
  }

  teardown() {
    removeCurrent(this);
  }

  matches(pathname) {
    let match, params, exact;

    for (const {
      matcher,
      callback,
      name
    } of this._routes) {
      [match, params, exact] = matcher(pathname);
      if (match === undefined) continue;
      this._result = {
        outlet: callback(params, history.state),
        match: name,
        exact
      };
      return match;
    }

    this._result = {
      outlet: this.fallback,
      match: undefined,
      exact: false
    };
  }

});

const useSearchParams = () => useMemo(() => {
  const searchParams = new URLSearchParams(location.search);
  return [...searchParams].reduce((obj, [key, value]) => ({ ...obj,
    [key]: value
  }), {});
}, [location.search]);

const RouteContext = createContext({});
customElements.define('route-context', RouteContext.Provider);
const useRouteProvider = params => {
  const query = useSearchParams();
  return {
    ready: params !== undefined,
    params: params || {},
    query
  };
};
const useRoute = () => useContext(RouteContext);

const RouteProvider = ({
  params
}) => {
  const ctx = useRouteProvider(params || undefined);
  return html`<route-context .value=${ctx}><slot></slot></route-context>`;
};

customElements.define('route-provider', component(RouteProvider));

export { navigateTo, replaceTo, useRoute, useRoutes };
