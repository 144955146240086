import { html, component, useCallback } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/PlusIcon.js';
import { useManageArtboard } from '../hooks/artboard/manage.js';
import './scribbl-loader.js';
import './scribbl-button.js';

function ScribblCreateArtboard({}) {
  const {
    createArtboard,
    loading
  } = useManageArtboard();
  const create = useCallback(e => {
    e.stopPropagation();
    createArtboard();
  }, [createArtboard]);
  return html`
    <style>
      :host {
        display: flex;
      }
      span {
        font-size: 14px;
      }
      @media (max-width: 420px) {
        button > span {
          display: none;
        }
        button {
          width: 32px;
        }
      }
    </style>
    <scribbl-button variant="primary" size="sm">
      <button ?disabled=${loading} @click=${create}>
        ${loading ? html`<scribbl-loader></scribbl-loader>` : html`<plus-icon></plus-icon><span>New</span>`}
      </button>
    </scribbl-button>
  `;
}

customElements.define('scribbl-create-artboard', component(ScribblCreateArtboard));