import { html, component } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/PlusIcon.js';
import './scribbl-button.js';

function ScribblCreateSheet({}) {
  const create = e => {
    e.stopPropagation(); // @ts-ignore

    this.dispatchEvent(new CustomEvent('create', {
      detail: {}
    }));
  };

  return html`
    <style>
      :host {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      button {
        padding: 0;
        width: 32px;
        height: 32px;
      }
      span {
        text-transform: uppercase;
        font-size: 14px;
      }
    </style>
    <scribbl-button>
      <button @click=${create}><plus-icon></plus-icon></button>
    </scribbl-button>
  `;
}

customElements.define('scribbl-create-sheet', component(ScribblCreateSheet));