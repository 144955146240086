import { createContext, useCallback, useContext, useState } from '../../_snowpack/pkg/haunted.js';
export let Tool;

(function (Tool) {
  Tool["Pencil"] = "source-over";
  Tool["Eraser"] = "destination-out";
  Tool["Move"] = "move";
})(Tool || (Tool = {}));

export const ToolLabels = {
  [Tool.Pencil]: 'Pencil',
  [Tool.Eraser]: 'Eraser',
  [Tool.Move]: 'Move'
};
const ToolPickerContext = createContext({});
customElements.define('tool-picker-provider', ToolPickerContext.Provider);
export const useToolPickerProvider = () => {
  const [tool, _setTool] = useState(Tool.Pencil);
  const [strokeWidth, _setStrokeWidth] = useState(10);
  const setTool = useCallback(e => {
    _setTool(e.detail.tool);
  }, [_setTool]);
  const setStrokeWidth = useCallback(e => {
    _setStrokeWidth(+e.detail.value);
  }, [_setStrokeWidth]);
  return {
    tool,
    setTool,
    strokeWidth,
    setStrokeWidth
  };
};
export const useToolPicker = () => useContext(ToolPickerContext);