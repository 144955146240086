import { html, component } from '../../_snowpack/pkg/haunted.js';

function ScribblLoader({
  width: _width,
  height: _height,
  size: _size
}) {
  const width = _width || _height || '24px';
  const height = _height || _width || '24px';
  const size = _size || '2px';
  return html`
    <style>
      :host {
        display: block;
        box-sizing: border-box;
      }
      .loader {
        padding: 0.25rem;
        box-sizing: border-box;
        width: ${width};
        height: ${height};
        border-radius: 50%;
        border: ${size} solid currentColor;
        animation: loader-1 1s infinite linear alternate,
          loader-2 2s infinite linear;
      }
      @keyframes loader-1 {
        0% {
          clip-path: polygon(
            50% 50%,
            0 0,
            50% 0%,
            50% 0%,
            50% 0%,
            50% 0%,
            50% 0%
          );
        }
        12.5% {
          clip-path: polygon(
            50% 50%,
            0 0,
            50% 0%,
            100% 0%,
            100% 0%,
            100% 0%,
            100% 0%
          );
        }
        25% {
          clip-path: polygon(
            50% 50%,
            0 0,
            50% 0%,
            100% 0%,
            100% 100%,
            100% 100%,
            100% 100%
          );
        }
        50% {
          clip-path: polygon(
            50% 50%,
            0 0,
            50% 0%,
            100% 0%,
            100% 100%,
            50% 100%,
            0% 100%
          );
        }
        62.5% {
          clip-path: polygon(
            50% 50%,
            100% 0,
            100% 0%,
            100% 0%,
            100% 100%,
            50% 100%,
            0% 100%
          );
        }
        75% {
          clip-path: polygon(
            50% 50%,
            100% 100%,
            100% 100%,
            100% 100%,
            100% 100%,
            50% 100%,
            0% 100%
          );
        }
        100% {
          clip-path: polygon(
            50% 50%,
            50% 100%,
            50% 100%,
            50% 100%,
            50% 100%,
            50% 100%,
            0% 100%
          );
        }
      }
      @keyframes loader-2 {
        0% {
          transform: scaleY(1) rotate(0deg);
        }
        49.99% {
          transform: scaleY(1) rotate(135deg);
        }
        50% {
          transform: scaleY(-1) rotate(0deg);
        }
        100% {
          transform: scaleY(-1) rotate(-135deg);
        }
      }
    </style>
    <div class="loader"></div>
  `;
}

customElements.define('scribbl-loader', component(ScribblLoader));