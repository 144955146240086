import { createContext, useCallback, useContext, useState } from '../../_snowpack/pkg/haunted.js';
const ColorPickerContext = createContext({});
customElements.define('color-picker-provider', ColorPickerContext.Provider);
export const useColorPickerProvider = () => {
  const [color, _setColor] = useState('#000000');
  const setColor = useCallback(e => {
    _setColor(e.detail.color);
  }, [_setColor]);
  return {
    color,
    setColor
  };
};
export const useColorPicker = () => useContext(ColorPickerContext);