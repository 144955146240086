import { html, component } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/RefreshIcon.js';
import './scribbl-button.js';

function ScribblGeneratePreview(_props) {
  const generate = e => {
    e.stopPropagation(); // @ts-ignore

    this.dispatchEvent(new CustomEvent('generate', {
      detail: {}
    }));
  };

  return html`
    <style>
      :host {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      button {
        padding: 0;
        width: 32px;
        height: 32px;
      }
      refresh-icon {
        --i-icon-width: 20px;
        --i-icon-height: 20px;
      }
      span {
        text-transform: uppercase;
        font-size: 14px;
      }
    </style>
    <scribbl-button>
      <button @click=${generate}><refresh-icon></refresh-icon></button>
    </scribbl-button>
  `;
}

customElements.define('scribbl-generate-preview', component(ScribblGeneratePreview));