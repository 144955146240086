import { b as component } from '../../../common/lit-haunted-f8d3dca7.js';
import { i as iconStyle } from '../../../common/icon.esm-2747b86a.js';
import { h as html } from '../../../common/lit-html-3386f0db.js';

const style = iconStyle("outline");

function RefreshIcon() {
  return html`${style}<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
</svg>
`;
}

customElements.define("refresh-icon", component(RefreshIcon));
