import { component, html, useCallback, useEffect, useRef, useState } from '../../_snowpack/pkg/haunted.js';
import { useRoute } from '../../_snowpack/pkg/@martel/haunted-router.js';
import { forwardRef } from '../utils/index.js';
import { _user } from '../hooks/data.js';

function ScribblTitleInput() {
  const route = useRoute();
  const artboardId = route?.params?.artboardId;
  const sheetId = route?.query?.v;
  const inputRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState('');
  const getSheetRef = useCallback(() => {
    return _user.get(`artboards`).get(artboardId).get(`sheets`).get(sheetId);
  }, [artboardId, sheetId]);
  useEffect(() => {
    if (!artboardId) return;
    getSheetRef().once(data => {
      if (!data) return;
      const {
        title
      } = data;
      setTitle(title);
    });
  }, [artboardId, sheetId]);
  const updateTitle = useCallback(_title => {
    getSheetRef().put({
      title: _title,
      updatedAt: new Date().toISOString()
    });
  }, [getSheetRef]);
  const onTitleUpdate = useCallback(e => {
    updateTitle(e.target.value);
    setEdit(false);
  }, [updateTitle, setEdit]);
  useEffect(() => {
    if (edit) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [edit]);
  return html`
    <style>
      :host {
        display: flex;
        align-items: center;
        max-width: 240px;
        flex-shrink: 1;
        min-width: 0;
      }
      @media (min-width: 640px) {
        :host {
          max-width: 400px;
        }
      }
      @media (min-width: 768px) {
        :host {
          max-width: 520px;
        }
      }
      @media (min-width: 1024px) {
        :host {
          max-width: 640px;
        }
      }
      input {
        flex: 1 1 auto;
        padding: 0.325rem 0.5rem;
        transition: border-color 150ms ease-out;
        appearance: none;
        border: 1px solid transparent;
        background: var(--sc-colors-gray100);
        overflow: hidden;
      }
      input:focus {
        border-color: var(--sc-colors-gray200);
        outline: none;
      }
      input,
      h1 {
        box-sizing: border-box;
        margin: 0;
        color: black;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        border-radius: 0.5rem;
      }
      h1 {
        transition: background 150ms ease-out;
        padding: 0.75rem 0.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
      }
      h1:hover {
        background: var(--sc-colors-gray100);
      }
    </style>
    ${edit ? html`<input
          ref=${forwardRef(inputRef)}
          part="input"
          .value=${title}
          @input=${e => setTitle(e.target.value)}
          @blur=${onTitleUpdate}
        />` : html`<h1 part="title" @click=${() => setEdit(true)}>${title}</h1>`}
  `;
}

customElements.define('scribbl-title-input', component(ScribblTitleInput));