import { html, component, useCallback } from '../../_snowpack/pkg/haunted.js';

function ScribblDrawerSection({
  label,
  collapsed
}) {
  const toggle = useCallback(() => {
    // @ts-ignore
    this.dispatchEvent(new CustomEvent('toggle', {
      bubbles: true,
      composed: true,
      detail: {
        label,
        collapsed
      }
    }));
  }, [label, collapsed]);
  return html`
    <style>
      section {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      header {
        z-index: 1;
        border-bottom: 1px solid var(--sc-colors-gray200);
        cursor: pointer;
        transition: all 150ms ease-out;
        ${!collapsed ? `position: sticky;` : ''}
        top: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.75rem;
        background-color: white;
        height: var(--drawer-section-header-height, 52px);
      }
      header:hover {
        border-color: var(--sc-colors-gray300);
        background-color: var(--sc-colors-gray100);
      }
      .content {
        box-sizing: border-box;
        display: flex;
        background-color: var(--sc-colors-gray50);
        border-bottom: 1px solid var(--sc-colors-gray200);
      }
      h3 {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 1;
      }
    </style>
    <section>
      <header @click=${toggle}>
        <h3>${label}</h3>
        <slot name="header"></slot>
      </header>
      ${!collapsed ? html`<div class="content"><slot></slot></div>` : ''}
    </section>
  `;
}

customElements.define('scribbl-drawer-section', component(ScribblDrawerSection, {
  observedAttributes: ['label', 'collapsed']
}));