import { html, component } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/TrashIcon.js';
import './scribbl-image.js';
import './scribbl-link.js';
import './scribbl-button.js';
import { date } from '../utils/index.js';
import { useConfirmDelete } from '../hooks/confirm-delete.js';

function getLink(id) {
  const [_, artboardId, __, sheetId] = id.split('artboards').pop().split('/');
  return `/artboards/${artboardId}?v=${sheetId}`;
}

function ScribblArtboardsCard({
  artboard
}) {
  // @ts-ignore
  const component = this;
  const [confirmDelete, setConfirmDelete] = useConfirmDelete(component, artboard?.id);
  const sheetCount = artboard?.sheetCount - 1;
  return html`
    <style>
      :host {
        overflow: hidden;
        display: block;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
      }

      scribbl-link {
        box-sizing: border-box;
        display: block;
        position: relative;
        overflow: hidden;
        ${confirmDelete ? html`--img-visibility: hidden;` : ''}
      }

      scribbl-link::part(link) {
        box-sizing: border-box;
        transition: background-color 150ms ease-out;
        text-decoration: none;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
        ${confirmDelete ? html`color: white; background-color: var(--sc-colors-red600); border:
          1px solid var(--sc-colors-red600); --img-placeholder-color:
          var(--sc-colors-red600);` : html`color: black; border: 1px solid var(--sc-colors-gray300); `}
        border-radius: 0.5rem;
      }

      scribbl-link::part(link)::after {
      ${confirmDelete ? html`font-size: 20px; position: absolute; left: 0; padding: 0 16px;
          top: 40%; content: 'Are you sure you want to delete artboard?';` : ''}
      }
      scribbl-link::part(link):hover {
        ${confirmDelete ? '' : html` background-color: var(--sc-colors-primary50);
          --img-placeholder-color: var(--sc-colors-primary100);`}
      }

      trash-icon {
        ${confirmDelete ? html` --i-icon-color: var(--sc-colors-red600); ` : html` --i-icon-color: var(--sc-colors-gray500); `}
      }

      scribbl-link:hover h3 {
        ${confirmDelete ? '' : html` color: var(--sc-colors-primary600); `}
      }

      .card-title {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0.75rem;
      }

      .card-badge {
        flex-shrink: 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        background-color: var(--sc-colors-gray600);
        color: white;
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
      }

      h3 {
        transition: color 150ms ease-out;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        margin: 0;
        flex: 1 1 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      time {
        flex-shrink: 0;
      }

      scribbl-button {
        z-index: 1;
        box-sizing: border-box;
        position: absolute;
        top: 2px;
        right: 2px;
      }

      button {
        box-sizing: border-box;
        border-top: none;
        border-right: none;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      button:hover {
        border-color: transparent;
      }
      button:hover trash-icon {
        ${confirmDelete ? html` --i-icon-color: var(--sc-colors-red600); ` : html` --i-icon-color: var(--sc-colors-gray900); `}
      }
    </style>

    <scribbl-link href=${getLink(artboard.id)}>
      <scribbl-button>
        <button @click=${setConfirmDelete}>
          <trash-icon></trash-icon>
        </button>
      </scribbl-button>
      <scribbl-image
        src=${artboard.preview}
        height="200px"
        width="auto"
      ></scribbl-image>
      <div class="card-title">
        <h3>${artboard.title}</h3>
        ${sheetCount ? html`<span class="card-badge">+${sheetCount} more</span>` : ''}
        <time datetime=${artboard.updatedAt}>
          ${date(artboard.updatedAt).fromNow()}
        </time>
      </div>
    </scribbl-link>
  `;
}

customElements.define('scribbl-artboards-card', component(ScribblArtboardsCard));