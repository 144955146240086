import { html, component, useCallback } from '../../_snowpack/pkg/haunted.js';
import { navigateTo, replaceTo } from '../../_snowpack/pkg/@martel/haunted-router.js';

const ScribblLink = ({
  href,
  state,
  replace: _replace
}) => {
  const replace = Boolean(_replace);
  const handleNavigation = useCallback(e => {
    e.preventDefault();
    const fn = replace ? replaceTo : navigateTo;
    fn(href, state);
  }, [href, state, replace]);
  return html`
    <style>
      :host {
        display: inline-block;
      }
    </style>
    <a part="link" href="${href}" @click=${handleNavigation}>
      <slot></slot>
    </a>
  `;
};

customElements.define('scribbl-link', component(ScribblLink, {
  observedAttributes: ['href']
}));