import { html, component } from '../../_snowpack/pkg/haunted.js';
const variants = {
  primary: {
    color: 'white',
    backgroundColor: 'var(--sc-colors-primary500)',
    borderColor: 'transparent',
    _hover: {
      backgroundColor: 'var(--button-bg-hover-color, var(--sc-colors-primary600))',
      borderColor: 'transparent'
    }
  },
  base: {
    color: 'var(--button-text-color, var(--sc-colors-gray900))',
    backgroundColor: 'var(--button-bg-color, white)',
    borderColor: 'var(--button-border-color, var(--sc-colors-gray300))',
    _hover: {
      backgroundColor: 'var(--button-bg-color, white)',
      borderColor: 'var(--button-border-hover-color, var(--sc-colors-gray900))'
    }
  }
};
const sizes = {
  sm: {
    height: '32px'
  },
  base: {
    height: '40px'
  }
};

function ScribblButton({
  variant: _variant,
  size: _size,
  width
}) {
  const size = sizes[_size || 'base'];
  const variant = variants[_variant || 'base'];
  return html`
    <style>
      :host,
      ::slotted(button), ::slotted(a), ::slotted(label) {
        font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        box-sizing: border-box;
      }
      ::slotted(button), ::slotted(a), ::slotted(label) {
        appearance: none;
        cursor: pointer;
        transition: all 150ms ease-out;
        color: var(--button-color, ${variant.color});
        background-color: ${variant.backgroundColor};
        border: 1px solid ${variant.borderColor};
        font-size: 0.875rem;
        line-height: 0;
        font-weight: bold;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.375rem 0.75rem;
        height: ${size.height};
        ${width ? html`width: ${width};` : ''}
        border-radius: 0.25rem;
      }
      ::slotted(button:hover), ::slotted(a:hover), ::slotted(label:hover) {
        background-color: ${variant._hover.backgroundColor};
        border-color: ${variant._hover.borderColor};
      }
      ::slotted(button:disabled), ::slotted(a:disabled), ::slotted(label:disabled)  {
        opacity: 0.6;
        cursor: not-allowed;
      }
    </style>
    <slot></slot>
  `;
}

customElements.define('scribbl-button', component(ScribblButton, {
  observedAttributes: ['width', 'size', 'variant', 'form']
}));