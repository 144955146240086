import { html, component } from '../../_snowpack/pkg/haunted.js';
import { useManageArtboardProvider } from '../hooks/artboard/manage.js';
import { useListArtboardsProvider } from '../hooks/artboard/list.js';

function ScribblArtboardListProvider() {
  const manage = useManageArtboardProvider();
  const list = useListArtboardsProvider();
  return html`
    <manage-artboard-context .value=${manage}>
      <list-artboards-context .value=${list}>
        <slot></slot>
      </list-artboards-context>
    </manage-artboard-provider>
  `;
}

customElements.define('scribbl-artboard-list-provider', component(ScribblArtboardListProvider));