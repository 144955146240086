import { html, component } from '../../_snowpack/pkg/haunted.js';
import { useRoute } from '../../_snowpack/pkg/@martel/haunted-router.js';
import { useManageArtboardProvider } from '../hooks/artboard/manage.js';
import { useViewArtboardProvider } from '../hooks/artboard/view.js';
import { useDrawerProvider } from '../hooks/drawer.js';

function ScribblArtboardProvider() {
  const route = useRoute();
  const manage = useManageArtboardProvider(route?.params?.artboardId);
  const view = useViewArtboardProvider({
    id: route?.params?.artboardId,
    v: route?.query?.v
  });
  const drawer = useDrawerProvider();
  return html`
    <manage-artboard-context .value=${manage}>
      <view-artboard-context .value=${view}>
        <drawer-context .value=${drawer}><slot></slot></drawer-context>
      </view-artboard-context>
    </manage-artboard-provider>
  `;
}

customElements.define('scribbl-artboard-provider', component(ScribblArtboardProvider));