import { html, component, useState, useCallback, useEffect } from '../../_snowpack/pkg/haunted.js';
import { useRoute } from '../../_snowpack/pkg/@martel/haunted-router.js';
import { _user } from '../hooks/data.js';

function ScribblImagePreview() {
  const route = useRoute();
  const artboardId = route?.params?.artboardId;
  const sheetId = route?.query?.v;
  const [src, setSrc] = useState('');
  const getSheetRef = useCallback(() => {
    return _user.get(`artboards`).get(artboardId).get(`sheets`).get(sheetId);
  }, [artboardId, sheetId]);
  useEffect(() => {
    if (!artboardId) return;
    let ev = null; // @ts-ignore

    getSheetRef().on((data, _k, _m, _e) => {
      ev = _e;
      if (!data) return;
      const {
        preview
      } = data;
      setSrc(preview);
    });
    return () => {
      ev?.off?.();
    };
  }, [artboardId, sheetId]);
  return html`
    <style>
      :host {
        box-sizing: border-box;
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
      }
      .image-preview {
        z-index: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .no-preview {
        z-index: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        margin: 0;
        line-height: 1;
      }
    </style>
    ${src ? html`<img class="image-preview" src=${src} alt="image preview" />` : html`<div class="no-preview"><p>No preview</p></div>`}
  `;
}

customElements.define('scribbl-image-preview', component(ScribblImagePreview, {
  observedAttributes: ['src']
}));