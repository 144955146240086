import { createContext, useContext, useState, useEffect, useCallback, useRef } from '../../../_snowpack/pkg/haunted.js';
import { _user } from '../data.js';
const ListArtboardsContext = createContext({});
customElements.define('list-artboards-context', ListArtboardsContext.Provider);
export const useListArtboardsProvider = () => {
  const [, update] = useState(0);
  const batchRef = useRef(null);
  const artboardsRef = useRef(new Map());
  const loadArtboards = useCallback(() => {
    artboardsRef.current = new Map();
    if (batchRef.current) clearTimeout(batchRef.current);

    _user.get('artboards').map((ab, key) => {
      if (!ab) return undefined;
      artboardsRef.current.set(key, null);
      return ab;
    }).get('sheets').map((sh, key) => {
      if (!sh) return undefined;
      return sh;
    }).once((sh, key) => {
      if (!sh) return;
      const id = sh._?.['#'];
      const idArr = id.split('/');
      const artboardId = idArr[idArr.length - 3];
      const artboard = artboardsRef.current.get(artboardId);

      if (artboard && artboard.updatedAt > sh.updatedAt) {
        artboardsRef.current.set(artboardId, { ...artboard,
          sheetCount: (artboard.sheetCount || 0) + 1
        });
        return;
      }

      const {
        createdAt,
        updatedAt,
        title,
        preview
      } = sh;
      artboardsRef.current.set(artboardId, {
        id,
        sheetCount: (artboard?.sheetCount || 0) + 1,
        createdAt,
        updatedAt,
        title,
        preview
      });
      if (batchRef.current) clearTimeout(batchRef.current);
      batchRef.current = setTimeout(update, 10);
    });
  }, []);
  useEffect(() => {
    loadArtboards();
  }, [loadArtboards]);
  const deleteArtboard = useCallback(artboardFullId => {
    const [_, artboardId] = artboardFullId.split('artboards').pop().split('/');
    artboardsRef.current.delete(artboardId);
    update(Date.now());

    _user.get('artboards').get(artboardId).put(null);
  }, [update]);
  const artboards = [...artboardsRef.current].map(([, sh]) => sh);
  return {
    artboards,
    deleteArtboard
  };
};
export const useListArtboards = () => useContext(ListArtboardsContext);