import { html, component } from '../../_snowpack/pkg/haunted.js';
import './scribbl-color-swatch.js';

function ScribblSelectedSwatch({
  color
}) {
  return html`
    <style>
      :host {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      scribbl-color-swatch {
        --color-swatch-width: 24px;
        --color-swatch-height: 24px;
      }
      span {
        text-transform: uppercase;
        font-size: 14px;
      }
    </style>
    <span>${color}</span>
    <scribbl-color-swatch
      color=${color}
      ?disabled=${true}
    ></scribbl-color-swatch>
  `;
}

customElements.define('scribbl-selected-swatch', component(ScribblSelectedSwatch, {
  observedAttributes: ['color']
}));