import Gun from '../../_snowpack/pkg/gun/gun.js';
import '../../_snowpack/pkg/gun/lib/radix.js';
import '../../_snowpack/pkg/gun/lib/radisk.js';
import '../../_snowpack/pkg/gun/lib/store.js';
import '../../_snowpack/pkg/gun/lib/rindexed.js';
import '../../_snowpack/pkg/gun/sea.js';
import { Buffer } from '../../_snowpack/pkg/buffer.js';
window.Buffer = Buffer;
window.setImmediate = setTimeout;
import { createContext, useCallback, useContext, useEffect, useState } from '../../_snowpack/pkg/haunted.js';
import { navigateTo } from '../../_snowpack/pkg/@martel/haunted-router.js';
import { dbConfig } from '../config.js';
export const db = Gun(dbConfig);
export const user = db.user.bind(db);
export let _user = user();

_user.recall({
  sessionStorage: true
});

const recallLocalStorage = () => {
  if (user().is) return;

  try {
    if ('localStorage' in window) {
      const pair = window.localStorage.getItem('pair');
      if (pair === null) return;
      user().auth(JSON.parse(pair));
      _user = user();
    }
  } catch (e) {}
};

recallLocalStorage();

const loadUser = async pubKey => await new Promise(resolve => user().is ? pubKey ? db.user(pubKey).once(resolve) : db.user().once(resolve) : resolve(null));

const loginUser = async (alias, password) => new Promise(resolve => user().auth(alias, password, resolve));

const createUser = async (alias, password) => new Promise(resolve => user().create(alias, password, resolve));

const AuthContext = createContext({});
customElements.define('auth-provider', AuthContext.Provider);
export const useAuthProvider = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const loadCurrentUser = useCallback(async () => {
    const _currentUser = await loadUser();

    _user = user();
    setCurrentUser(_currentUser);
  }, [setCurrentUser]);
  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);
  const login = useCallback(async (alias, password) => {
    const res = await loginUser(alias, password);
    if (res.err) return res;
    localStorage.setItem('pair', JSON.stringify(res.sea));
    await loadCurrentUser();
    navigateTo('/');
    return res;
  }, [loadCurrentUser]);
  const register = useCallback(async (alias, password) => {
    const res = await createUser(alias, password);
    if (res.err) return res;
    return await login(alias, password);
  }, [login]);
  const logout = useCallback(() => {
    user().leave();
    localStorage.removeItem('pair');
    setCurrentUser(null);
    navigateTo('/session');
  }, [setCurrentUser]);
  return {
    user: currentUser,
    setUser: setCurrentUser,
    register,
    login,
    logout
  };
};
export const useAuth = () => useContext(AuthContext);