import { html, component, useCallback } from '../../_snowpack/pkg/haunted.js';
import { repeat } from '../../_snowpack/pkg/lit-html/directives/repeat.js';
import './scribbl-image.js';
import './scribbl-link.js';
import './scribbl-artboards-card.js';
import { useListArtboards } from '../hooks/artboard/list.js';

function ScribblArtboardsList() {
  const {
    artboards,
    deleteArtboard
  } = useListArtboards();
  const onDelete = useCallback(e => {
    deleteArtboard(e.detail.id);
  }, [deleteArtboard]);
  const items = artboards?.filter(a => a?.id).sort((a, b) => +b?.updatedAt - +a?.updatedAt);
  return html`
    <style>
      :host {
        display: block;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        min-height: calc(100vh - var(--header-height, 60px));
        height: 100%;
        width: 100%;
      }
      .grid {
        padding: 1rem 0.75rem;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, 1fr);
      }
      @media (min-width: 768px) {
        .grid {
          grid-template-columns: repeat(auto-fit, minmax(320px, 0.5fr));
        }
      }
      @media (min-width: 1024px) {
        .grid {
          grid-template-columns: repeat(auto-fit, minmax(400px, 0.5fr));
        }
      }
      @media (min-width: 1280px) {
        .grid {
          grid-template-columns: repeat(auto-fit, minmax(420px, 0.333333fr));
        }
      }
      @media (min-width: 1400px) {
        .grid {
          grid-template-columns: repeat(auto-fit, minmax(420px, 0.25fr));
        }
      }
    </style>

    <div class="grid">
      ${items ? repeat(items, artboard => artboard?.id, artboard => html`<scribbl-artboards-card
                .artboard=${artboard}
                @delete=${onDelete}
              ></scribbl-artboards-card>`) : ''}
    </div>
  `;
}

customElements.define('scribbl-artboards-list', component(ScribblArtboardsList));