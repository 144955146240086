import { b as component } from '../../../common/lit-haunted-f8d3dca7.js';
import { i as iconStyle } from '../../../common/icon.esm-2747b86a.js';
import { h as html } from '../../../common/lit-html-3386f0db.js';

const style = iconStyle("outline");

function TrashIcon() {
  return html`${style}<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
</svg>
`;
}

customElements.define("trash-icon", component(TrashIcon));
