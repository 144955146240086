import { html, component } from '../../_snowpack/pkg/haunted.js';
import { useRoute } from '../../_snowpack/pkg/@martel/haunted-router.js';
import { useAuth } from '../hooks/data.js';
import '../hooks/artboard/manage.js';
import '../hooks/drawer.js';
import './scribbl-header.js';

function ScribblLayout({
  fixed = false
}) {
  const {
    user
  } = useAuth();
  const route = useRoute();
  return html`
    <style>
      :host {
        box-sizing: border-box;
        ${fixed ? `
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        ` : ''}
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    </style>
    ${user ? html`
          <scribbl-header ?drawer=${!!route?.params?.artboardId}>
            <slot name="header"></slot>
          </scribbl-header>
          <slot></slot>
        ` : html` <slot></slot> `}
  `;
}

customElements.define('scribbl-layout', component(ScribblLayout, {
  observedAttributes: ['fixed']
}));