import { useCallback, useEffect, useRef, useState } from '../../_snowpack/pkg/haunted.js';
export const useConfirmDelete = (component, id) => {
  const cancel = useRef(null);
  const [confirmDelete, _setConfirmDelete] = useState(0);
  const setConfirmDelete = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();

    _setConfirmDelete(prev => (prev || 0) + 1);
  }, [_setConfirmDelete]);
  useEffect(() => {
    if (!confirmDelete || !id) return;

    if (confirmDelete === 1) {
      cancel.current = setTimeout(() => {
        _setConfirmDelete(0);
      }, 2000);
      return;
    }

    if (confirmDelete === 2) {
      clearTimeout(cancel.current);

      _setConfirmDelete(0); // do the delete now


      component.dispatchEvent(new CustomEvent('delete', {
        detail: {
          id
        },
        bubbles: true,
        composed: true
      }));
    }
  }, [confirmDelete, id]);
  return [confirmDelete, setConfirmDelete];
};