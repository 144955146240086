import { html, component } from '../../_snowpack/pkg/haunted.js';
import { Tool } from '../hooks/tool-picker.js';
import './scribbl-artboard-tool.js';
const defaultTools = Object.values(Tool);

function ScribblToolPicker({
  selected
}) {
  return html`
    <style>
      :host {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .tools {
        z-index: 0;
        box-sizing: border-box;
        width: 100%;
        display: grid;
        grid-gap: 0.25rem;
        grid-template-columns: repeat(auto-fit, minmax(52px, 1fr));
        padding: 0.75rem 0;
      }
      scribbl-artboard-tool {
        --tool-width: auto;
        --tool-box-align: center;
        --tool-box-justify: center;
      }
    </style>
    <div class="tools">
      ${defaultTools.map(tool => html`<scribbl-artboard-tool
            .tool=${tool}
            ?selected=${tool === selected}
          ></scribbl-artboard-tool>`)}
    </div>
  `;
}

customElements.define('scribbl-tool-picker', component(ScribblToolPicker, {
  observedAttributes: ['selected']
}));