import { html, component, useState, useCallback } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/PhotographIcon.js';

function ScribblImage({
  src,
  height: _height,
  width: _width
}) {
  const height = _height || _width;
  const width = _width || _height;
  const [loading, setLoading] = useState(true);
  const [placeholder, setPlaceholder] = useState(false);
  const resolve = useCallback(e => {
    if (e.type === 'error') {
      setPlaceholder(true);
    }

    setLoading(false);
  }, [setLoading]);
  return html`
    <style>
      :host {
        display: block;
        overflow: hidden;
        box-sizing: border-box;
        height: ${height || 'auto'};
        width: ${width || '100%'};
        visibility: var(--img-visibility);
        color: var(--img-placeholder-color, var(--sc-colors-gray200));
        transition: color 150ms ease-out;
      }
      @keyframes loading {
        0 {
          opacity: 1;
        }
        20% {
          opacity: 0.2;
        }
        100% {
          opacity: 1;
          transform: translateX(100%);
        }
      }
      .loading {
        position: relative;
        overflow: hidden;
        height: ${height};
        width: ${width};
        background-color: var(--img-placeholder-bg, var(--sc-colors-gray100));
        transition: all 150ms ease-out;
      }
      .loading::after {
        position: absolute;
        overflow: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        will-change: transform, opacity, background-color;
        transform: translateX(-100%);
        background: linear-gradient(
          106deg,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 0.3) 20%,
          rgba(255, 255, 255, 0.5) 80%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: loading 2s forwards cubic-bezier(0.9, 0.03, 0.69, 0.22)
          infinite;
      }
      img {
        position: absolute;
        left: 0;
        width: auto;
        height: 100%;
        opacity: 0;
        transition: opacity 150ms ease-in;
      }
      .placeholder {
        height: 100%;
        width: 100%;
        --i-icon-width: 100%;
        --i-icon-height: 100%%;
      }
      .loaded {
        opacity: 1;
      }
      .container {
        height: 0;
        padding-bottom: 47.9%;
        position: relative;
      }
    </style>
    ${loading ? html`<div class="loading"></div>` : ''}
    ${placeholder ? html`<photograph-icon class="placeholder"></photograph-icon>` : src ? html` <div class="container">
          <img
            class=${loading ? '' : 'loaded'}
            src=${src}
            @error=${resolve}
            @load=${resolve}
          />
        </div>` : ''}
  `;
}

customElements.define('scribbl-image', component(ScribblImage, {
  observedAttributes: ['src', 'height', 'width']
}));