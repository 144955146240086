import { navigateTo } from '../../_snowpack/pkg/@martel/haunted-router.js';
import { html } from '../../_snowpack/pkg/haunted.js';
import { _user } from '../hooks/data.js';
import './scribbl-layout.js';
import './scribbl-session.js';
import './scribbl-artboard-provider.js';
import './scribbl-artboard-list-provider.js';
import './scribbl-artboards-list.js';
import './scribbl-title-input.js';
import './scribbl-artboard.js';
import './scribbl-sheet-view.js';
var RouteMode; // Helper decorator for auth

(function (RouteMode) {
  RouteMode["Any"] = "any";
  RouteMode["Unauthenticated"] = "unauth";
  RouteMode["Authenticated"] = "auth";
})(RouteMode || (RouteMode = {}));

const guard = (route, {
  mode = RouteMode.Any
} = {}) => (...args) => {
  if (mode === RouteMode.Any) {
    return html`
        <route-provider .params=${args[0]}>${route(...args)}</route-provider>
      `;
  } // @ts-ignore


  if (mode === RouteMode.Authenticated && !_user.is) {
    // navigateTo('/session');
    return html`
        <route-provider .params=${args[0]}>
          <scribbl-session></scribbl-session>
        </route-provider>
      `;
  } // @ts-ignore


  if (mode === RouteMode.Unauthenticated && _user.is) {
    navigateTo('/');
    return html`
        <route-provider .params=${args[0]}>
          <scribbl-artboard-list-provider>
            <scribbl-layout>
              <scribbl-artboards-list></scribbl-artboards-list>
            </scribbl-layout>
          </scribbl-artboard-list-provider>
        </route-provider>
      `;
  }

  return html`
      <route-provider .params=${args[0]}>${route(...args)}</route-provider>
    `;
};

export const routes = {
  '/': guard(() => {
    return html`
        <scribbl-artboard-list-provider>
          <scribbl-layout>
            <scribbl-artboards-list></scribbl-artboards-list>
          </scribbl-layout>
        </scribbl-artboard-list-provider>
      `;
  }, {
    mode: RouteMode.Authenticated
  }),
  '/s/:sheetId': guard(() => {
    return html`<scribbl-sheet-view></scribbl-sheet-view>`;
  }, {
    mode: RouteMode.Any
  }),
  '/artboards/:artboardId': guard(() => {
    return html`
        <style>
          scribbl-title-input {
            margin-left: 40px;
          }
        </style>
        <scribbl-artboard-provider>
          <scribbl-layout ?fixed=${true}>
            <scribbl-title-input slot="header"></scribbl-title-input>
            <scribbl-artboard></scribbl-artboard>
          </scribbl-layout>
        </scribbl-artboard-provider>
      `;
  }, {
    mode: RouteMode.Authenticated
  }),
  '/session': guard(() => {
    return html`<scribbl-session></scribbl-session>`;
  }, {
    mode: RouteMode.Unauthenticated
  }) // An URL with parameters

  /* '/product/:id': ({ id }) => html`<x-page-product .id=${id}></x-page-product>`, */
  // Dynamically import the component

  /* '/about': () => { */
  // No need to wait for the result, the component will appear once loaded

  /* import('./about.js'); */

  /* return html`<x-page-about></x-page-about>`; */

  /* }, */
  // Putting a star at the end will match all the URLs that starts with the string
  // It can be used to match subroutes

  /* '/account*': () => html`<x-page-account></x-page-account>`, */

};