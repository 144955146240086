import { html, component, useCallback, useRef, useEffect, useMemo } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/CheckIcon.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/PencilIcon.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/EraserIcon.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/HandIcon.js';
import { Tool } from '../hooks/tool-picker.js';
import { forwardRef } from '../utils/index.js';
const TOOL_EVENT_SELECTED = 'tool-selected';

function ScribblArtboardTool({
  tool,
  selected: _selected,
  disabled: _disabled
}) {
  const ref = useRef(null);
  const selected = Boolean(_selected);
  const disabled = Boolean(_disabled);
  const onSelect = useCallback(() => {
    if (!disabled) {
      // @ts-ignore
      const _tool = this;

      _tool.dispatchEvent(new CustomEvent(TOOL_EVENT_SELECTED, {
        bubbles: true,
        composed: true,
        detail: {
          tool
        }
      }));
    }
  }, [disabled, tool]);
  useEffect(() => {
    if (disabled || !selected) return;

    if (ref.current) {
      if ('scrollIntoView' in ref.current) {
        ref.current.scrollIntoView({
          block: 'center'
        });
        return;
      }

      if ('scrollIntoViewIfNeeded' in ref.current) {
        ref.current.scrollIntoViewIfNeeded(true);
      }
    }
  }, [disabled, selected]);
  const toolIcon = useMemo(() => {
    switch (tool) {
      case Tool.Move:
        return html`<hand-icon></hand-icon>`;

      case Tool.Eraser:
        return html`<eraser-icon></eraser-icon>`;

      case Tool.Pencil:
      default:
        return html`<pencil-icon></pencil-icon>`;
    }
  }, [tool]);
  return html`
    <style>
      .tool {
        position: relative;
        display: flex;
        transition: background-color 150ms ease-out;
        align-items: var(--tool-box-align, flex-end);
        justify-content: var(--tool-box-justify, flex-end);
        cursor: pointer;
        width: var(--tool-width, 52px);
        height: var(--tool-height, 52px);
        border-radius: var(--tool-border-radius, 0.25rem);
      }
      .tool:hover {
        ${!disabled ? html` background-color: var(--sc-colors-gray100); ` : ''}
      }
      pencil-icon {
        --i-icon-width: 20px;
        --i-icon-height: 20px;
      }
      check-icon {
        position: absolute;
        right: 0.875rem;
        bottom: 0;
        --i-icon-width: 18px;
        --i-icon-height: 18px;
        border-radius: 9999px;
        background-color: white;
        color: green;
        padding: 0.25rem;
        margin: 0.15rem;
      }
    </style>
    <div class="tool" @click=${onSelect} ref=${forwardRef(ref)}>
      ${toolIcon} ${selected ? html`<check-icon></check-icon>` : ''}
    </div>
  `;
}

customElements.define('scribbl-artboard-tool', component(ScribblArtboardTool, {
  observedAttributes: ['tool', 'selected', 'disabled']
}));