import { html, component } from '../../_snowpack/pkg/haunted.js';
import { useRoute } from '../../_snowpack/pkg/@martel/haunted-router.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/TrashIcon.js';
import { useManageArtboard } from '../hooks/artboard/manage.js';
import './scribbl-loader.js';
import './scribbl-button.js';
import { useViewArtboard } from '../hooks/artboard/view.js';
import { useConfirmDelete } from '../hooks/confirm-delete.js';

function ScribblDeleteSheet(_props) {
  const route = useRoute();
  const artboardId = route?.params?.artboardId;
  const sheetId = route?.query?.v;
  const {
    sheets,
    setSheets
  } = useViewArtboard();
  const {
    deleteSheet,
    loading
  } = useManageArtboard();
  const component = {
    async dispatchEvent(event) {
      const {
        detail: {
          id
        }
      } = event;
      const remaining = sheets.filter(p => p?.id !== id);
      setSheets(remaining);
      deleteSheet(remaining, artboardId, id);
    }

  };
  const [confirmDelete, setConfirmDelete] = useConfirmDelete(component, sheetId);
  return html`
    <style>
      :host {
        display: flex;
      }
      trash-icon {
        --i-icon-height: 20px;
        --i-icon-width: 20px;
        --i-icon-color: ${confirmDelete ? html`var(--sc-colors-red600)` : html`var(--sc-colors-gray-900)`};
      }
      span {
        font-size: 14px;
        margin-left: 4px;
        color: ${confirmDelete ? html`var(--sc-colors-red600)` : html`var(--sc-colors-gray-900)`};
      }
      @media (max-width: 420px) {
        button > span {
          display: none;
        }
        button {
          width: 32px;
        }
      }
    </style>
    <scribbl-button size="sm">
      <button ?disabled=${loading} @click=${setConfirmDelete}>
        ${loading ? html`<scribbl-loader></scribbl-loader>` : html`<trash-icon></trash-icon
              ><span>${confirmDelete ? 'Are you sure?' : 'Delete'}</span>`}
      </button>
    </scribbl-button>
  `;
}

customElements.define('scribbl-delete-sheet', component(ScribblDeleteSheet));