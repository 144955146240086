import { navigateTo } from '../../../_snowpack/pkg/@martel/haunted-router.js';
import { encodeURI } from '../../../_snowpack/pkg/js-base64.js';
import { createContext, useCallback, useContext, useState } from '../../../_snowpack/pkg/haunted.js';
import { db, _user } from '../data.js';
const ManageArtboardContext = createContext({});
customElements.define('manage-artboard-context', ManageArtboardContext.Provider);
export const useManageArtboardProvider = id => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const artboardId = id || null;
  const createSheet = useCallback((_artboardId, timestamps = null) => {
    if (!_artboardId && !artboardId) return;
    setLoading(true);
    const id = _artboardId || artboardId;
    const createdAt = new Date().toISOString();

    const artboard = _user.get(`artboards`).get(id); // set latest sheet ref into the list of all sheets after available on the graph under
    // ref: userId/artboards/id/latest


    const latest = artboard.get('sheets').set({
      title: 'untitled',
      createdAt: !!_artboardId ? timestamps?.createdAt || createdAt : createdAt,
      updatedAt: !!_artboardId ? timestamps?.updatedAt || createdAt : createdAt
    }, () => {
      const sheetId = latest._.get;
      setLoading(false);
      navigateTo(`/artboards/${id}?v=${sheetId}`);
    });
  }, [artboardId, setLoading]);
  const deleteSheet = useCallback(async (sheets, _artboardId, sheetId) => {
    if (!_artboardId && !artboardId) return;
    setLoading(true);

    try {
      const id = _artboardId || artboardId;

      const artboard = _user.get(`artboards`).get(id);

      if (!sheets.length) {
        // Delete the artboard
        artboard.put(null);
        navigateTo(`/`);
        return;
      }

      const nextSheetId = sheets[0].id; // Set/Clear latest

      await new Promise(resolve => {
        artboard.get('sheets').get(sheetId).put(null, () => {
          resolve(null);
        });
      });
      navigateTo(`/artboards/${id}?v=${nextSheetId}`);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [artboardId, setLoading]);
  const createArtboard = useCallback(() => {
    setLoading(true);
    const createdAt = new Date().toISOString();

    const _artboard = _user.get('artboards').set({
      createdAt,
      updatedAt: createdAt
    }, // @ts-ignore
    (val, key) => {
      createSheet(_artboard._.get, _artboard._.put);
    });
  }, [createSheet, setLoading]);
  const saveArtboard = useCallback(async (data, sheetId) => {
    if (!artboardId || !sheetId) return;
    setSaving(true);

    try {
      const _id = sheetId.split('/').pop();

      await new Promise(resolve => _user.get('artboards').get(artboardId).get('sheets').get(_id).get('data').set(data, resolve));
      await new Promise(resolve => _user.get('artboards').get(artboardId).get('sheets').get(_id).get('updatedAt').put(Date.now(), resolve));
    } catch (err) {} finally {
      setSaving(false);
    }
  }, [artboardId, setSaving]);
  const savePreview = useCallback(async (preview, sheetId) => {
    if (!artboardId || !sheetId) return;
    setSaving(true);

    try {
      const _id = sheetId.split('/').pop();

      await new Promise(resolve => _user.get('artboards').get(artboardId).get('sheets').get(_id).get('preview').put(preview, resolve));
    } catch (err) {} finally {
      setSaving(false);
    }
  }, [artboardId, setSaving]);
  const saveBlobPreview = useCallback(async (preview, sheetId) => {
    if (!artboardId || !sheetId) return;
    setSaving(true);

    try {
      const _id = sheetId.split('/').pop();

      await new Promise(resolve => _user.get('artboards').get(artboardId).get('sheets').get(_id).get('preview').put(preview, resolve));
    } catch (err) {
      console.log(err);
    } finally {
      setSaving(false);
    }
  }, [artboardId, setSaving]);
  const setSheetSharing = useCallback((sheetId, enabled) => {
    if (!artboardId || !sheetId) return;

    try {
      const _id = sheetId.split('/').pop();

      if (enabled) {
        const currentSheet = _user.get('artboards').get(artboardId).get('sheets').get(_id);

        const sharedSheet = db.get('sheets').set(currentSheet, res => {
          if (res.ok) {
            const _shareId = encodeURI(sharedSheet._.link);

            _user.get('artboards').get(artboardId).get('sheets').get(_id).get('shareId') // @ts-ignore
            .put(_shareId);
          }
        });
      } else {
        const currentSheet = _user.get('artboards').get(artboardId).get('sheets').get(_id); // @ts-ignore


        currentSheet.get('shareId').once(shareId => {
          db.get('sheets').get(shareId).put(null);
          currentSheet.get('shareId').put(null);
        });
      }
    } catch (err) {}
  }, [artboardId]);
  return {
    loading,
    saving,
    createArtboard,
    createSheet,
    setSheetSharing,
    saveArtboard,
    savePreview,
    saveBlobPreview,
    deleteSheet
  };
};
export const useManageArtboard = () => useContext(ManageArtboardContext);