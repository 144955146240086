import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import dayjs from '../../_snowpack/pkg/dayjs.js';
import updateLocale from '../../_snowpack/pkg/dayjs/plugin/updateLocale.js';
import relativeTime from '../../_snowpack/pkg/dayjs/plugin/relativeTime.js';
import { directive, AttributePart } from '../../_snowpack/pkg/lit-html.js';
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '< 1min',
    m: '1min',
    mm: '%dmins',
    h: '1hr',
    hh: '%dhrs',
    d: '1d',
    dd: '%dd',
    M: '1m',
    MM: '%dm',
    y: '1y',
    yy: '%dy'
  }
});
export const date = dayjs;
export const forwardRef = directive(refInstance => part => {
  if (!(part instanceof AttributePart)) {
    throw new Error('ref directive can only be used as an attribute');
  }

  refInstance.current = part.committer.element;
});
export const logErrors = err => {
  if (err._debounced) {
    return;
  }

  console.log(err);
};

let _pixelRatio;

export function getDevicePixelRatio() {
  if (_pixelRatio) {
    return _pixelRatio;
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  _pixelRatio = function () {
    const devicePixelRatio = typeof window !== 'undefined' && window.devicePixelRatio || 1;
    const backingStoreRatio = context.webkitBackingStorePixelRatio || context.mozBackingStorePixelRatio || context.msBackingStorePixelRatio || context.oBackingStorePixelRatio || context.backingStorePixelRatio || 1;
    return devicePixelRatio / backingStoreRatio;
  }();

  return _pixelRatio;
}
export const urlCreator = window.URL || window.webkitURL;
export function createImageUrl(data) {
  return urlCreator.createObjectURL(data);
}
export const debug = (...args) => {
  if (false && __SNOWPACK_ENV__.MODE !== 'production') {
    console.log(...args);
  }
};