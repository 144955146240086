import { component, html, useCallback } from '../../_snowpack/pkg/haunted.js';
import { classMap } from '../../_snowpack/pkg/lit-html/directives/class-map.js';

function ScribblSwitch({
  square = false,
  checked = false
}) {
  const emitChange = useCallback(e => {
    // @ts-ignore
    this.dispatchEvent(new CustomEvent('change', {
      detail: {
        checked: Boolean(e.target.checked)
      },
      bubbles: true,
      composed: true
    }));
  }, []);
  return html`
    <style>
      .switch {
        position: relative;
        display: inline-block;
        width: 53px;
        height: 26px;
      }
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--sc-colors-gray200);
        -webkit-transition: 0.2s;
        transition: 0.2s;
      }
      .slider:before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.2s;
        transition: 0.2s;
      }
      input:checked + .slider {
        background-color: var(--sc-colors-primary400);
      }
      input:focus + .slider {
        box-shadow: 0 0 0 2px var(--sc-colors-primary200);
      }
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      .slider.round:before {
        border-radius: 50%;
      }
      .label {
        top: 3px;
        position: relative;
        left: 4px;
        color: #5c5858;
      }
    </style>
    <label class="switch">
      <input type="checkbox" ?checked=${checked} @click=${emitChange} />
      <span
        class=${classMap({
    slider: true,
    round: !square
  })}
      ></span>
    </label>
    <span class="label">
      <slot></slot>
    </span>
  `;
}

customElements.define('scribbl-switch', component(ScribblSwitch, {
  observedAttributes: ['square', 'checked']
}));