export class CanvasDocumentRenderer {
  constructor(doc) {
    this._doc = doc;
  }
  /**
   * Attach another document to the render
   * @param doc
   */


  attach(doc) {
    this._doc = doc;
  }
  /**
   * Render applies contiguous operations to the canvas document
   * @param fn
   * @param fromCursor?
   */


  render(fn, fromCursor = -1) {
    const doc = this._doc;
    let ops;
    const contiguousOps = doc.read(fromCursor);

    while (ops = contiguousOps.next().value) {
      fn(ops);
    }
  }

}