import { u as useMemo } from './common/lit-haunted-f8d3dca7.js';
export { b as component, c as createContext, a as useContext, d as useEffect, u as useMemo, e as useState } from './common/lit-haunted-f8d3dca7.js';
export { h as html } from './common/lit-html-3386f0db.js';

/**
 * @function
 * @template {Function} T
 * @param    {T} fn - callback to memoize
 * @param    {unknown[]} inputs - dependencies to callback memoization
 * @return   {T}
 */

const useCallback = (fn, inputs) => useMemo(() => fn, inputs);

/**
 * @function
 * @template T
 * @param   {T} initialValue
 * @return  {{ current: T }} Ref
 */

const useRef = initialValue => useMemo(() => ({
  current: initialValue
}), []);

export { useCallback, useRef };
