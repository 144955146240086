import { html, component } from '../../_snowpack/pkg/haunted.js';
import { nothing } from '../../_snowpack/pkg/lit-html.js';
import { useRoutes } from '../../_snowpack/pkg/@martel/haunted-router.js';
import './scribbl-link.js';
import { routes } from './routes.js';
import { useAuthProvider } from '../hooks/data.js';

function App({
  title: _title
}) {
  const auth = useAuthProvider();
  const {
    outlet
  } = useRoutes(routes, nothing);
  return html`
    <style>
      :host {
        display: block;
        box-sizing: border-box;
        height: 100%;
        user-select: none;
        --sc-colors-gray50: #f7fafc;
        --sc-colors-gray100: #edf2f7;
        --sc-colors-gray200: #e2e8f0;
        --sc-colors-gray300: #cbd5e0;
        --sc-colors-gray400: #a0aec0;
        --sc-colors-gray500: #718096;
        --sc-colors-gray600: #4a5568;
        --sc-colors-gray700: #2d3748;
        --sc-colors-gray800: #1a202c;
        --sc-colors-gray900: #171923;
        --sc-colors-primary50: #f2e7ff;
        --sc-colors-primary100: #dcc4fe;
        --sc-colors-primary200: #c59cfe;
        --sc-colors-primary300: #ad70fe;
        --sc-colors-primary400: #984afd;
        --sc-colors-primary500: #8218fb;
        --sc-colors-primary600: #7611f4;
        --sc-colors-primary700: #6500ec;
        --sc-colors-primary800: #5300e7;
        --sc-colors-primary900: #2d00e2;
        --sc-colors-red600: #f41146;
      }
    </style>
    <auth-provider .value=${auth}> ${outlet}</auth-provider>
  `;
}

customElements.define('app-root', component(App, {
  observedAttributes: ['title']
}));