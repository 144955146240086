import { html, component, useCallback } from '../../_snowpack/pkg/haunted.js';

function ScribblRange({
  value,
  name,
  min,
  max,
  step,
  ariaLabel,
  disabled
}) {
  const handleInput = useCallback(e => {
    const value = Number(e.target.value); // @ts-ignore

    this.dispatchEvent(new CustomEvent('change', {
      bubbles: true,
      composed: true,
      detail: {
        value
      }
    }));
  }, []);
  return html`
    <style>
      :host {
        --thumb-size: 20px;
        --track-color: var(--sc-colors-gray200);
        --track-height: 6px;
        display: block;
      }
      input {
        -webkit-appearance: none;
        width: 100%;
        height: 32px;
        background: transparent;
        line-height: 32px;
        vertical-align: middle;
      }

      input::-webkit-slider-runnable-track {
        width: 100%;
        height: var(--track-height);
        background-color: var(--track-color);
        border-radius: 3px;
        border: none;
      }

      input::-webkit-slider-thumb {
        border: none;
        width: var(--thumb-size);
        height: var(--thumb-size);
        border-radius: 50%;
        background-color: var(--sc-colors-primary500);
        border: solid 1px var(--sc-colors-primary500);
        -webkit-appearance: none;
        margin-top: calc(var(--thumb-size) / -2 + var(--track-height) / 2);
        transition: 150ms border-color, 150ms background-color, 150ms color,
          150ms box-shadow, 150ms transform;
        cursor: pointer;
      }

      input:not(:disabled)::-webkit-slider-thumb:hover {
        background-color: var(--sc-colors-primary400);
        border-color: var(--sc-colors-primary400);
      }

      input:not(:disabled):focus::-webkit-slider-thumb {
        background-color: var(--sc-colors-primary400);
        border-color: var(--sc-colors-primary400);
        box-shadow: 0 0 0 2px var(--sc-colors-primary200);
      }

      input:not(:disabled)::-webkit-slider-thumb:active {
        background-color: var(--sc-colors-primary500);
        border-color: var(--sc-colors-primary500);

        cursor: grabbing;
      }

      input::-moz-focus-outer {
        border: 0;
      }

      input::-moz-range-track {
        width: 100%;
        height: var(--track-height);
        background-color: var(--track-color);
        border-radius: 3px;
        border: none;
      }

      input::-moz-range-thumb {
        border: none;
        height: var(--thumb-size);
        width: var(--thumb-size);
        border-radius: 50%;
        background-color: var(--sc-colors-primary500);
        border-color: var(--sc-colors-primary500);
        transition: 150ms border-color, 150ms background-color, 150ms color,
          150ms box-shadow, 150ms transform;
        cursor: pointer;
      }

      input:not(:disabled)::-moz-range-thumb:hover {
        background-color: var(--sc-colors-primary400);
        border-color: var(--sc-colors-primary400);
      }

      input:not(:disabled):focus::-moz-range-thumb {
        background-color: var(--sc-colors-primary400);
        border-color: var(--sc-colors-primary400);
        box-shadow: 0 0 0 2px var(--sc-colors-primary200);
      }

      input:not(:disabled)::-moz-range-thumb:active {
        background-color: var(--sc-colors-primary600);
        border-color: var(--sc-colors-primary600);
        cursor: grabbing;
      }

      input:focus {
        outline: none;
      }

      input:disabled {
        opacity: 0.5;
      }

      input:disabled::-webkit-slider-thumb {
        cursor: not-allowed;
      }

      input:disabled::-moz-range-thumb {
        cursor: not-allowed;
      }
    </style>
    <input
      type="range"
      name=${name}
      ?disabled=${disabled}
      min=${min}
      max=${max}
      step=${step}
      .value=${value}
      aria-label=${ariaLabel}
      @input=${handleInput}
    />
  `;
}

customElements.define('scribbl-range', component(ScribblRange, {
  observedAttributes: ['value', 'name', 'min', 'max', 'step', 'aria-label', 'disabled']
}));