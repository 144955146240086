import { html, component, useCallback } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/DocumentDownloadIcon.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/DocumentAddIcon.js';
import '../../_snowpack/pkg/haunted-heroicons/outline/esm/CheckIcon.js';
import './scribbl-button.js';
import './scribbl-switch.js';

function ScribblSheetFile({
  shareId = ''
}) {
  const toggleShare = useCallback(e => {
    // @ts-ignore
    this.dispatchEvent(new CustomEvent('toggle-share', {
      detail: {
        value: e.detail.checked
      },
      bubbles: true,
      composed: true
    }));
  }, []);
  const emitDownloadDocument = useCallback(() => {
    // @ts-ignore
    this.dispatchEvent(new CustomEvent('download-document', {
      detail: {},
      bubbles: true,
      composed: true
    }));
  }, []);
  const emitDownloadSvg = useCallback(() => {
    // @ts-ignore
    this.dispatchEvent(new CustomEvent('download-svg', {
      detail: {},
      bubbles: true,
      composed: true
    }));
  }, []);
  const emitImportDocument = useCallback(e => {
    // @ts-ignore
    this.dispatchEvent(new CustomEvent('import-document', {
      detail: {
        file: e.target.files[0]
      },
      bubbles: true,
      composed: true
    }));
  }, []);
  const shareable = !!shareId;
  return html`
    <style>
      :host {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .options {
        z-index: 0;
        box-sizing: border-box;
        width: var(--viewport-width, 100%);
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
      .option {
        transition: background-color 150ms ease-out, color 150ms ease-out;
        color: var(--sc-colors-gray600);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0.75rem;
      }
      .option:not(:last-child) {
        padding-bottom: 0 !important;
      }
      .option-content {
        align-items: center;
        justify-content: space-between;
        display: flex;
        gap: 0.25rem;
      }
      .label {
        margin: 0;
        font-size: 0.75rem;
        line-height: 1rem;
        text-transform: uppercase;
        font-weight: semibold;
      }
      scribbl-button {
        flex: 1;
      }
      document-add-icon,
      document-download-icon {
        --i-icon-width: 20px;
        --i-icon-height: 20px;
      }
      label,
      button {
        flex: 1;
        padding: 0;
        height: 32px;
        width: 100%;
      }
      .half {
        width: 50%;
      }
      .share-link {
        box-sizing: border-box;
        flex: 1;
        font-size: 1rem;
        height: 32px;
      }
      [aria-hidden='true'] {
        display: none;
      }
      span {
        font-size: 14px;
        margin-left: 4px;
      }
    </style>
    <div class="options">
      <div class="option">
        <div class="option-content">
          <h4 class="label">Share</h4>
          <scribbl-switch @change=${toggleShare} ?checked=${shareable}></scribbl-switch>
        </div>
        ${shareable ? html`<div class="option-content">
              <input
                class="share-link"
                type="text"
                readonly
                .value=${`${window.location.origin}/s/${shareId}`}
                @focus=${e => e.target.select()}
              />
            </div>` : ''}
      </div>
      <div class="option">
        <h4 class="label">Export</h4>
        <div class="option-content">
          <scribbl-button>
            <button @click=${emitDownloadDocument}>
              <document-download-icon></document-download-icon>
              <span>Document</span>
            </button>
          </scribbl-button>
          <scribbl-button>
            <button @click=${emitDownloadSvg}>
              <document-download-icon></document-download-icon>
              <span>Svg</span>
            </button>
          </scribbl-button>
        </div>
      </div>
      <div class="option">
        <h4 class="label">Import</h4>
        <div class="option-content">
          <scribbl-button>
            <label class="half">
              <input accept="application/json" type="file" aria-hidden="true" @change=${emitImportDocument} />
              <document-add-icon></document-add-icon><span>Document</span>
            </label>
          </scribbl-button>
        </div>
      </div>
    </div>
  `;
}

customElements.define('scribbl-sheet-file', component(ScribblSheetFile, {
  observedAttributes: ['shareId']
}));