import { useRef, useCallback, useEffect, useMemo, createContext, useContext } from '../../_snowpack/pkg/haunted.js';
import { debug } from '../utils/index.js';
import { useColorPickerProvider } from './color-picker.js';
import { useToolPickerProvider } from './tool-picker.js';
import { CanvasDocument, lineJoin, strokeOperations } from '../utils/doc.js';
import { CanvasDocumentRenderer } from '../utils/render.js'; // import { CanvasDocumentBuffer } from '../utils/buffer'

import { Camera } from '../utils/camera.js'; // TODO: optimize canvas operations
//  https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas
//  - Batch Calls https://www.html5rocks.com/en/tutorials/canvas/performance/#toc-batch
//  - Come up with some operational format that allows for:
//    - reasonable loading time
//    - fast updates
//    - fast saves
//    - ability to generate an imageUrl to store and view for general viewing purposes (ie. thumbnails/export)
//  - Change storage format to UintClampedArray?
//  - fix saving so that it is patching data diffs and only saving what has changed in a given timeframe

export const useCanvasProvider = () => {
  const colorPicker = useColorPickerProvider();
  const toolPicker = useToolPickerProvider();
  const activeTool = toolPicker.tool;
  const strokeWidth = toolPicker.strokeWidth;
  const strokeColor = colorPicker.color;
  const batchRef = useRef(null);
  const docRef = useRef(null);
  const renderRef = useRef(null); // const bufferRef = useRef<CanvasDocumentBuffer>(null as any)

  const stageRef = useRef(null);
  const dimensionsRef = useRef({
    width: 0,
    height: 0
  }); // const bufferCanvasRef = useRef<HTMLCanvasElement>(null as any)
  // const bufferContextRef = useRef<CanvasRenderingContext2D>(null as any)

  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const cameraRef = useRef(new Camera({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  }));
  const activeRef = useRef(false); // const scaleRef = useRef(1)

  const prevRef = useRef({
    x: 0,
    y: 0
  });
  const isTouch = useMemo(() => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  }, []); // const createBuffer = useCallback((dpi?: number) => {
  //   if (bufferCanvasRef.current) {
  //     return
  //   }
  //   // temporary canvas
  //   bufferCanvasRef.current = document.createElement('canvas')
  //   let scale = scaleRef.current as number
  //   if (scale === dpi) {
  //     scale = scale / (dpi * 1.0)
  //   }
  //   // original canvas
  //   const canvas = canvasRef.current as unknown as HTMLCanvasElement
  //   const tmpCtx = bufferCanvasRef.current.getContext('2d') as unknown as CanvasRenderingContext2D
  //   bufferContextRef.current = tmpCtx
  //   bufferCanvasRef.current.width = canvas.width // * scale;
  //   bufferCanvasRef.current.height = canvas.height // * scale;
  //   // bufferCanvasRef.current.style.height = canvas.style.height;
  //   // bufferCanvasRef.current.style.width = canvas.style.width;
  //   tmpCtx.scale(scale, scale)
  //   tmpCtx.drawImage(canvas, 0, 0)
  // }, [])
  // const clearBuffer = useCallback(() => {
  //   bufferCanvasRef.current = null as any
  //   bufferContextRef.current = null as any
  // }, [])
  // const applyBuffer = useCallback(() => {
  //   // original canvas context
  //   const ctx = contextRef.current
  //   // buffer canvas
  //   const bufferCanvas = bufferCanvasRef.current
  //   // apply to real canvas
  //   requestAnimationFrame(() => {
  //     debug({ apply: bufferCanvas })
  //     ctx.drawImage(bufferCanvas, 0, 0)
  //     clearBuffer()
  //   })
  // }, [])

  const render = useCallback((fromCursor, context) => {
    if (batchRef.current) {
      clearTimeout(batchRef.current);
    } // Batch these for offscreen drawing


    ;
    batchRef.current = setTimeout(() => {
      // Render the document to the canvas using optionally a provided one
      ;
      (context?.renderer || renderRef.current)?.render(ops => {
        const ctx = context?.canvasContext || contextRef.current;

        if (ops[0].ot !== undefined) {
          debug({
            render: ops
          }); // ctx.save()
          // Arrange the context operations for the contiguous chunk

          const ctxOp = ops[0];
          ctx.globalCompositeOperation = strokeOperations[ctxOp.ot];
          ctx.lineWidth = ctxOp.lw;
          ctx.lineCap = lineJoin[ctxOp.lc];
          ctx.lineJoin = lineJoin[ctxOp.lj];
          ctx.strokeStyle = ctxOp.cc; // Array<CanvasStrokeOperation>

          let e = ops.length - 1;
          ctx.beginPath();

          for (let i = 0; i < e; i++) {
            // render the points
            const {
              mx,
              my,
              px,
              py
            } = ops[i];
            ctx.moveTo(mx, my);
            ctx.lineTo(px, py);
          }

          ctx.closePath();
          ctx.stroke(); // ctx.restore()
        } else {// Array<CanvasRectOperation>
        }
      }, fromCursor); // TODO: check to see if there is a way to notify when a document is completely loaded, defer the apply and clear to after the document is completely loaded.
      // apply and clear out buffer
      // applyBuffer()
    }, 100);
  }, []);
  const refresh = useCallback((force = false) => {
    const stage = stageRef.current;
    const width = stage.clientWidth;
    const height = stage.clientHeight;
    dimensionsRef.current = {
      width,
      height
    };
    cameraRef.current.viewport.x2 = cameraRef.current.viewport.x1 + width;
    cameraRef.current.viewport.y2 = cameraRef.current.viewport.y1 + height; // canvasOffsetX =
    //   element.x > x1
    //     ? distance(element.x, x1) * window.devicePixelRatio * zoom.value
    //     : 0;
    // canvasOffsetY =
    //   element.y > y1
    //     ? distance(element.y, y1) * window.devicePixelRatio * zoom.value
    //     : 0;
    // context.translate(canvasOffsetX, canvasOffsetY);
    // Stage area dimensions/dpi scale

    const canvas = canvasRef.current; // canvas.width = width * scaleRef.current
    // canvas.height = height * scaleRef.current

    canvas.width = cameraRef.current.width;
    canvas.height = cameraRef.current.height; // canvas.style.height = height + 'px'
    // canvas.style.width = width + 'px'
    // contextRef.current.save()

    contextRef.current.translate(cameraRef.current.zoom, cameraRef.current.zoom);
    contextRef.current.scale(window.devicePixelRatio * cameraRef.current.zoom, window.devicePixelRatio * cameraRef.current.zoom); // contextRef.current.restore()
    // canvas.style.height = height + 'px'
    // canvas.style.width = width + 'px'
    // contextRef.current.scale(scaleRef.current, scaleRef.current)

    if (!docRef.current || force) {
      docRef.current = new CanvasDocument(width, height);
    }

    if (!renderRef.current || force) {
      renderRef.current = new CanvasDocumentRenderer(docRef.current);
    } // if (!bufferRef.current || force) {
    //   bufferRef.current = new CanvasDocumentBuffer(docRef.current)
    // }

  }, []);
  const clear = useCallback(() => {
    refresh(true);
  }, []);
  const resize = useCallback(() => {
    // scaleRef.current = getDevicePixelRatio()
    if (!contextRef.current) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      contextRef.current = ctx;
    }

    refresh();
    debug({
      resize: true
    });
    render(0);
  }, []);
  const loadFromData = useCallback(data => {
    if (data && typeof data === 'object' && docRef.current?.shouldRender(data)) {
      if (batchRef.current) {
        clearTimeout(batchRef.current);
      } // Load in the document from persisted storage format


      docRef.current?.deserializeAndMerge(data);
      debug({
        load: true
      });
      render();
    }
  }, []);
  const saveAsDataUrl = useCallback(() => {
    const canvas = canvasRef.current;
    return canvas.toDataURL("image/webp", 0.7);
  }, []);
  const saveAsCanvasDoc = useCallback(() => {
    if (!docRef.current?.empty()) {
      return docRef.current?.commit();
    }

    return null;
  }, []);
  const exportAsCanvasDoc = useCallback(() => {
    return docRef.current;
  }, []);
  const exportAsCanvasSvg = useCallback(async () => {
    // @ts-ignore
    const CanvasToSvg = await import("../../_snowpack/pkg/svgcanvas.js").then(({
      Context
    }) => ({
      Context
    }));
    const canvas = canvasRef.current;
    const svgCtx = new CanvasToSvg.Context({
      width: canvas.width,
      height: canvas.height
    }); // TODO: refactor the draw calls to use alternate contexts, rerender to svg context so the svg doesn't output an
    // image as a single child el

    svgCtx.drawImage(canvas, 0, 0); // render(0, { canvasContext: svgCtx })
    // svg string

    return svgCtx.getSerializedSvg();
  }, []);
  const importAsCanvasDoc = useCallback(doc => {
    docRef.current?.import(doc);
  }, []); // const saveAsImageData = useCallback(() => {
  //   const canvas = canvasRef.current as unknown as HTMLCanvasElement;
  //   const ctx = contextRef.current as unknown as CanvasRenderingContext2D;
  //   return ctx.getImageData(0, 0, canvas.width, canvas.height);
  // }, []);
  //

  const saveAsBlob = useCallback(() => {
    const canvas = canvasRef.current;
    return new Promise(resolve => canvas.toBlob(resolve, "image/webp", 1.0));
  }, []);
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    window.addEventListener('orientationchange', resize);
    return () => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('orientationchange', resize);
    };
  }, []);
  const applyContext = useCallback(ctx => {
    ctx.globalCompositeOperation = activeTool;
    ctx.lineWidth = strokeWidth;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.strokeStyle = strokeColor;
  }, [strokeColor, strokeWidth, activeTool]);
  const start = useCallback(() => {
    const ctx = contextRef.current;
    applyContext(ctx);
  }, [applyContext]);
  const draw = useCallback((x, y) => {
    const canvas = canvasRef.current;
    const pos = {
      x: x - canvas.offsetLeft,
      y: y - canvas.offsetTop
    };
    const prev = prevRef.current;
    const ctx = contextRef.current;
    requestAnimationFrame(() => {
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(prev.x, prev.y);
      ctx.lineTo(pos.x, pos.y);
      ctx.closePath();
      ctx.stroke();
      ctx.restore();
    });
    prevRef.current = pos;
    window.requestIdleCallback(() => {
      docRef.current?.line(prev.x, prev.y, pos.x, pos.y, ctx.globalCompositeOperation, ctx.strokeStyle, ctx.lineWidth, ctx.lineJoin, ctx.lineCap);
    });
  }, []);
  const onMouseDown = useCallback(e => {
    if (isTouch) return;
    const active = e.buttons === 1;
    activeRef.current = active;

    if (active) {
      const canvas = canvasRef.current;
      prevRef.current = {
        x: e.clientX - canvas.offsetLeft,
        y: e.clientY - canvas.offsetTop
      };
      start();
    }
  }, [start]);
  const onTouchStart = useCallback(e => {
    if (!isTouch) return;
    const active = e.touches.length === 1;
    activeRef.current = active;

    if (active) {
      const canvas = canvasRef.current;
      prevRef.current = {
        x: e.touches[0].clientX - canvas.offsetLeft,
        y: e.touches[0].clientY - canvas.offsetTop
      };
      start();
    }
  }, [start]);
  const onMouseUp = useCallback(() => {
    activeRef.current = false;
  }, []);
  const onMouseMove = useCallback(e => {
    if (activeRef.current) {
      draw(e.clientX, e.clientY);
    }
  }, [draw]);
  const onTouchMove = useCallback(e => {
    if (activeRef.current) {
      draw(e.touches[0].clientX, e.touches[0].clientY);
    }
  }, [draw]);
  return {
    stageRef,
    canvasRef,
    activeRef,
    prevRef,
    colorPicker,
    toolPicker,
    strokeWidth,
    strokeColor,
    isTouch,
    onTouchStart,
    onTouchMove,
    onMouseUp,
    onMouseDown,
    onMouseMove,
    loadFromData,
    saveAsCanvasDoc,
    saveAsDataUrl,
    saveAsBlob,
    exportAsCanvasDoc,
    importAsCanvasDoc,
    exportAsCanvasSvg,
    clear
  };
};
export const UseCanvasContext = createContext({});
customElements.define('artboard-canvas-provider', UseCanvasContext.Provider);
export const useCanvas = () => useContext(UseCanvasContext);
export const useCanvasDocument = () => {
  const {
    saveAsDataUrl,
    saveAsCanvasDoc
  } = useCanvas();
  return useMemo(() => ({
    saveAsCanvasDoc,
    saveAsDataUrl
  }), [saveAsCanvasDoc, saveAsDataUrl]);
};