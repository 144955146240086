try {
  self["workbox:window:6.5.2"] && _();
} catch (n) {}

function n(n, t) {
  return new Promise(function (r) {
    var e = new MessageChannel();
    e.port1.onmessage = function (n) {
      r(n.data);
    }, n.postMessage(t, [e.port2]);
  });
}

function t(n, t) {
  for (var r = 0; r < t.length; r++) {
    var e = t[r];
    e.enumerable = e.enumerable || !1, e.configurable = !0, "value" in e && (e.writable = !0), Object.defineProperty(n, e.key, e);
  }
}

function r(n, t) {
  (null == t || t > n.length) && (t = n.length);

  for (var r = 0, e = new Array(t); r < t; r++) e[r] = n[r];

  return e;
}

function e(n, t) {
  var e;

  if ("undefined" == typeof Symbol || null == n[Symbol.iterator]) {
    if (Array.isArray(n) || (e = function (n, t) {
      if (n) {
        if ("string" == typeof n) return r(n, t);
        var e = Object.prototype.toString.call(n).slice(8, -1);
        return "Object" === e && n.constructor && (e = n.constructor.name), "Map" === e || "Set" === e ? Array.from(n) : "Arguments" === e || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(e) ? r(n, t) : void 0;
      }
    }(n)) || t && n && "number" == typeof n.length) {
      e && (n = e);
      var i = 0;
      return function () {
        return i >= n.length ? {
          done: !0
        } : {
          done: !1,
          value: n[i++]
        };
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  return (e = n[Symbol.iterator]()).next.bind(e);
}

try {
  self["workbox:core:6.5.2"] && _();
} catch (n) {}

var i = function () {
  var n = this;
  this.promise = new Promise(function (t, r) {
    n.resolve = t, n.reject = r;
  });
};

function o(n, t) {
  var r = location.href;
  return new URL(n, r).href === new URL(t, r).href;
}

var u = function (n, t) {
  this.type = n, Object.assign(this, t);
};

function a(n, t, r) {
  return r ? t ? t(n) : n : (n && n.then || (n = Promise.resolve(n)), t ? n.then(t) : n);
}

function c() {}

var f = {
  type: "SKIP_WAITING"
};

function s(n, t) {
  if (!t) return n && n.then ? n.then(c) : Promise.resolve();
}

var v = function (r) {
  var e, c;

  function v(n, t) {
    var e, c;
    return void 0 === t && (t = {}), (e = r.call(this) || this).nn = {}, e.tn = 0, e.rn = new i(), e.en = new i(), e.on = new i(), e.un = 0, e.an = new Set(), e.cn = function () {
      var n = e.fn,
          t = n.installing;
      e.tn > 0 || !o(t.scriptURL, e.sn.toString()) || performance.now() > e.un + 6e4 ? (e.vn = t, n.removeEventListener("updatefound", e.cn)) : (e.hn = t, e.an.add(t), e.rn.resolve(t)), ++e.tn, t.addEventListener("statechange", e.ln);
    }, e.ln = function (n) {
      var t = e.fn,
          r = n.target,
          i = r.state,
          o = r === e.vn,
          a = {
        sw: r,
        isExternal: o,
        originalEvent: n
      };
      !o && e.mn && (a.isUpdate = !0), e.dispatchEvent(new u(i, a)), "installed" === i ? e.wn = self.setTimeout(function () {
        "installed" === i && t.waiting === r && e.dispatchEvent(new u("waiting", a));
      }, 200) : "activating" === i && (clearTimeout(e.wn), o || e.en.resolve(r));
    }, e.dn = function (n) {
      var t = e.hn,
          r = t !== navigator.serviceWorker.controller;
      e.dispatchEvent(new u("controlling", {
        isExternal: r,
        originalEvent: n,
        sw: t,
        isUpdate: e.mn
      })), r || e.on.resolve(t);
    }, e.gn = (c = function (n) {
      var t = n.data,
          r = n.ports,
          i = n.source;
      return a(e.getSW(), function () {
        e.an.has(i) && e.dispatchEvent(new u("message", {
          data: t,
          originalEvent: n,
          ports: r,
          sw: i
        }));
      });
    }, function () {
      for (var n = [], t = 0; t < arguments.length; t++) n[t] = arguments[t];

      try {
        return Promise.resolve(c.apply(this, n));
      } catch (n) {
        return Promise.reject(n);
      }
    }), e.sn = n, e.nn = t, navigator.serviceWorker.addEventListener("message", e.gn), e;
  }

  c = r, (e = v).prototype = Object.create(c.prototype), e.prototype.constructor = e, e.__proto__ = c;
  var h,
      l,
      w = v.prototype;
  return w.register = function (n) {
    var t = (void 0 === n ? {} : n).immediate,
        r = void 0 !== t && t;

    try {
      var e = this;
      return function (n, t) {
        var r = n();
        if (r && r.then) return r.then(t);
        return t(r);
      }(function () {
        if (!r && "complete" !== document.readyState) return s(new Promise(function (n) {
          return window.addEventListener("load", n);
        }));
      }, function () {
        return e.mn = Boolean(navigator.serviceWorker.controller), e.yn = e.pn(), a(e.bn(), function (n) {
          e.fn = n, e.yn && (e.hn = e.yn, e.en.resolve(e.yn), e.on.resolve(e.yn), e.yn.addEventListener("statechange", e.ln, {
            once: !0
          }));
          var t = e.fn.waiting;
          return t && o(t.scriptURL, e.sn.toString()) && (e.hn = t, Promise.resolve().then(function () {
            e.dispatchEvent(new u("waiting", {
              sw: t,
              wasWaitingBeforeRegister: !0
            }));
          }).then(function () {})), e.hn && (e.rn.resolve(e.hn), e.an.add(e.hn)), e.fn.addEventListener("updatefound", e.cn), navigator.serviceWorker.addEventListener("controllerchange", e.dn), e.fn;
        });
      });
    } catch (n) {
      return Promise.reject(n);
    }
  }, w.update = function () {
    try {
      return this.fn ? s(this.fn.update()) : void 0;
    } catch (n) {
      return Promise.reject(n);
    }
  }, w.getSW = function () {
    return void 0 !== this.hn ? Promise.resolve(this.hn) : this.rn.promise;
  }, w.messageSW = function (t) {
    try {
      return a(this.getSW(), function (r) {
        return n(r, t);
      });
    } catch (n) {
      return Promise.reject(n);
    }
  }, w.messageSkipWaiting = function () {
    this.fn && this.fn.waiting && n(this.fn.waiting, f);
  }, w.pn = function () {
    var n = navigator.serviceWorker.controller;
    return n && o(n.scriptURL, this.sn.toString()) ? n : void 0;
  }, w.bn = function () {
    try {
      var n = this;
      return function (n, t) {
        try {
          var r = n();
        } catch (n) {
          return t(n);
        }

        if (r && r.then) return r.then(void 0, t);
        return r;
      }(function () {
        return a(navigator.serviceWorker.register(n.sn, n.nn), function (t) {
          return n.un = performance.now(), t;
        });
      }, function (n) {
        throw n;
      });
    } catch (n) {
      return Promise.reject(n);
    }
  }, h = v, (l = [{
    key: "active",
    get: function () {
      return this.en.promise;
    }
  }, {
    key: "controlling",
    get: function () {
      return this.on.promise;
    }
  }]) && t(h.prototype, l), v;
}(function () {
  function n() {
    this.Pn = new Map();
  }

  var t = n.prototype;
  return t.addEventListener = function (n, t) {
    this.Sn(n).add(t);
  }, t.removeEventListener = function (n, t) {
    this.Sn(n).delete(t);
  }, t.dispatchEvent = function (n) {
    n.target = this;

    for (var t, r = e(this.Sn(n.type)); !(t = r()).done;) {
      (0, t.value)(n);
    }
  }, t.Sn = function (n) {
    return this.Pn.has(n) || this.Pn.set(n, new Set()), this.Pn.get(n);
  }, n;
}());

export { v as Workbox, u as WorkboxEvent, n as messageSW };
