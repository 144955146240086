import { html, component, useCallback, useEffect, useRef } from '../../_snowpack/pkg/haunted.js';
import '../../_snowpack/pkg/scrollable-component.js';
import { debug, forwardRef, logErrors } from '../utils/index.js';
import { useDrawer } from '../hooks/drawer.js';
import { useCanvasProvider } from '../hooks/canvas.js';
import '../hooks/artboard/view.js';
import './scribbl-hamburger.js';
import './scribbl-drawer.js';
import './scribbl-drawer-section.js';
import './scribbl-color-picker.js';
import './scribbl-selected-swatch.js';
import './scribbl-tool-picker.js';
import './scribbl-selected-tool.js';
import './scribbl-create-sheet.js';
import './scribbl-sheet-share.js';
import './scribbl-sheet-file.js';
import './scribbl-generate-preview.js';
import './scribbl-image-preview.js';
import './scribbl-sheets.js';
import './scribbl-text-logo.js';
import './scribbl-range.js';
import './scribbl-link.js';
import { useManageArtboard } from '../hooks/artboard/manage.js';
import { useViewArtboard } from '../hooks/artboard/view.js';
import { polyfilled } from '../utils/polyfills.js';
import { useDrawerSection } from '../hooks/drawer-section.js';
var DrawerSectionLabel;

(function (DrawerSectionLabel) {
  DrawerSectionLabel["Tools"] = "Tools";
  DrawerSectionLabel["Colors"] = "Colors";
  DrawerSectionLabel["Sheets"] = "Sheets";
  DrawerSectionLabel["File"] = "File";
  DrawerSectionLabel["Preview"] = "Preview";
})(DrawerSectionLabel || (DrawerSectionLabel = {}));

function ScribblArtboard({}) {
  const [collapsed, toggleCollapsed] = useDrawerSection(DrawerSectionLabel);
  const mountedRef = useRef(false);
  const debounceRef = useRef(null);
  const {
    saveArtboard,
    savePreview,
    saveBlobPreview,
    createSheet: _createSheet,
    setSheetSharing: _setSheetSharing
  } = useManageArtboard();
  const {
    sheet,
    artboard,
    sheets
  } = useViewArtboard();
  const {
    isOpen: drawerOpen,
    setClosed: closeDrawer
  } = useDrawer();
  const canvasCtx = useCanvasProvider();
  const {
    colorPicker,
    toolPicker,
    strokeWidth,
    stageRef,
    canvasRef,
    saveAsCanvasDoc,
    exportAsCanvasDoc,
    importAsCanvasDoc,
    exportAsCanvasSvg,
    saveAsBlob,
    saveAsDataUrl,
    loadFromData,
    clear,
    isTouch,
    onTouchStart,
    onTouchMove,
    onMouseUp: _onMouseUp,
    onMouseDown,
    onMouseMove
  } = canvasCtx;
  const createSheet = useCallback(e => {
    e.stopPropagation();

    _createSheet();
  }, [_createSheet]);
  const setSheetSharing = useCallback(e => {
    e.stopPropagation();

    _setSheetSharing(sheet?.id, e.detail.value);
  }, [sheet?.id, _setSheetSharing]);
  const generatePreview = useCallback(async () => {
    if (debounceRef.current) clearTimeout(debounceRef.current);
    await saveBlobPreview(await saveAsBlob(), sheet?.id).catch(logErrors);
    await savePreview(saveAsDataUrl(), sheet?.id).catch(logErrors);
  }, [sheet?.id]);
  const downloadDocument = useCallback(() => {
    const doc = exportAsCanvasDoc();
    const blob = new Blob([JSON.stringify(doc)], {
      type: 'application/json'
    });
    const url = window.URL.createObjectURL(blob);
    const elem = window.document.createElement('a');
    elem.style.display = 'none';
    elem.href = url;
    elem.download = `${sheet?.title}.json`;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    window.requestIdleCallback(() => {
      window.URL.revokeObjectURL(url);
    });
  }, [sheet?.title, exportAsCanvasDoc]);
  const downloadSvg = useCallback(async () => {
    const svg = await exportAsCanvasSvg();
    const blob = new Blob([svg], {
      type: 'image/svg+xml'
    });
    const url = window.URL.createObjectURL(blob);
    const elem = window.document.createElement('a');
    elem.style.display = 'none';
    elem.href = url;
    elem.download = `${sheet?.title}.svg`;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    window.requestIdleCallback(() => {
      window.URL.revokeObjectURL(url);
    });
  }, [sheet?.title, exportAsCanvasSvg]);
  const importDocument = useCallback(async e => {
    try {
      const doc = JSON.parse(await e.detail.file.text());
      importAsCanvasDoc(doc);
      window.requestIdleCallback(() => {
        const savedDoc = saveAsCanvasDoc();

        if (savedDoc) {
          saveArtboard(savedDoc, sheet?.id).then(() => {
            generatePreview();
          }).catch(logErrors);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [sheet?.id, importAsCanvasDoc, saveArtboard, saveAsCanvasDoc, generatePreview]);
  const onMouseUp = useCallback(() => {
    if (isTouch) return;

    _onMouseUp();

    if (debounceRef.current) clearTimeout(debounceRef.current);
    window.requestIdleCallback(() => {
      const doc = saveAsCanvasDoc();

      if (doc) {
        saveArtboard(doc, sheet?.id).catch(logErrors);
      }
    });

    if (!polyfilled.requestIdleCallback) {
      ;
      debounceRef.current = setTimeout(() => {
        ;
        window.requestIdleCallback(() => {
          generatePreview();
        });
      }, 10000);
    }
  }, [_onMouseUp, saveAsCanvasDoc, saveArtboard, sheet?.id]);
  const onTouchEnd = useCallback(e => {
    debug({
      touchend: e
    });
    if (!isTouch) return;

    _onMouseUp();

    if (debounceRef.current) clearTimeout(debounceRef.current);
    window.requestIdleCallback(() => {
      const doc = saveAsCanvasDoc();

      if (doc) {
        debug({
          saving: doc
        });
        saveArtboard(doc, sheet?.id).catch(logErrors);
      }
    });

    if (!polyfilled.requestIdleCallback) {
      ;
      debounceRef.current = setTimeout(() => {
        ;
        window.requestIdleCallback(() => {
          generatePreview();
        });
      }, 10000);
    }
  }, [_onMouseUp, saveAsCanvasDoc, saveArtboard, sheet?.id]);
  useEffect(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current);

    if (sheet?.id && mountedRef.current) {
      clear();
    }

    mountedRef.current = true;
    return () => {
      if (debounceRef.current) clearTimeout(debounceRef.current);
    };
  }, [sheet?.id]);
  useEffect(() => {
    debug({
      incoming: sheet?.data
    });

    if (sheet?.data?.sz) {
      loadFromData(sheet?.data);
    }
  }, [sheet?.data?.ca, loadFromData]);
  return html`
    <style>
      :host {
        display: block;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        height: calc(100vh - var(--header-height, 60px));
        width: 100%;
        user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
      }

      scribbl-drawer {
        --drawer-z-index: 10;
        --drawer-content-height: 100vh;
        --drawer-content-width: 300px;
        --drawer-modal-background-color: rgba(255, 255, 255, 0.6);
        --drawer-box-shadow: 1px 0 2px #00000024;
      }

      scribbl-color-picker {
        padding: 0 0.75rem;
      }

      scrollable-component {
        height: calc(100vh - 56px);
        --scrollbar-width: 10px;
      }

      ${isTouch ? html`:host, div { touch-action: none; }` : ''} div,
      canvas {
        user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        image-rendering: -moz-crisp-edges;
        image-rendering: -webkit-crisp-edges;
        image-rendering: pixelated;
        image-rendering: crisp-edges;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
      }

      scribbl-text-logo {
        padding-right: 2.75rem;
      }

      scribbl-range {
        padding: 0 0.75rem 1rem;
      }
    </style>
    <color-picker-provider .value=${colorPicker}>
      <tool-picker-provider .value=${toolPicker}>
        <scribbl-drawer .opened=${drawerOpen} @drawer-closed=${closeDrawer}>
          ${drawerOpen ? html`
                <scribbl-link href="/" slot="header">
                  <scribbl-text-logo></scribbl-text-logo>
                </scribbl-link>
                <scrollable-component>
                  <scribbl-drawer-section
                    label=${DrawerSectionLabel.Tools}
                    ?collapsed=${collapsed[DrawerSectionLabel.Tools]}
                    @toggle=${toggleCollapsed}
                  >
                    <scribbl-selected-tool slot="header" tool=${toolPicker.tool}></scribbl-selected-tool>
                    ${collapsed[DrawerSectionLabel.Tools] ? '' : html`<div>
                          <scribbl-tool-picker
                            .selected=${toolPicker.tool}
                            @tool-selected=${toolPicker.setTool}
                          ></scribbl-tool-picker>
                          <scribbl-range
                            @change=${toolPicker.setStrokeWidth}
                            .value=${strokeWidth}
                            .min=${1}
                            .max=${100}
                          ></scribbl-range>
                        </div>`}
                  </scribbl-drawer-section>
                  <scribbl-drawer-section
                    label=${DrawerSectionLabel.Colors}
                    ?collapsed=${collapsed[DrawerSectionLabel.Colors]}
                    @toggle=${toggleCollapsed}
                  >
                    <scribbl-selected-swatch slot="header" color=${colorPicker.color}></scribbl-selected-swatch>
                    ${collapsed[DrawerSectionLabel.Colors] ? '' : html`
                          <scribbl-color-picker
                            .selected=${colorPicker.color}
                            @swatch-selected=${colorPicker.setColor}
                          ></scribbl-color-picker>
                        `}
                  </scribbl-drawer-section>
                  <scribbl-drawer-section
                    label=${DrawerSectionLabel.Sheets}
                    ?collapsed=${collapsed[DrawerSectionLabel.Sheets]}
                    @toggle=${toggleCollapsed}
                  >
                    <scribbl-create-sheet slot="header" @create=${createSheet}></scribbl-create-sheet>
                    ${collapsed[DrawerSectionLabel.Sheets] ? '' : html` <scribbl-sheets
                          .artboardId=${artboard?.id}
                          .sheetId=${sheet?.id}
                          .sheets=${sheets}
                        ></scribbl-sheets>`}
                  </scribbl-drawer-section>
                  <scribbl-drawer-section
                    label=${DrawerSectionLabel.File}
                    ?collapsed=${collapsed[DrawerSectionLabel.File]}
                    @toggle=${toggleCollapsed}
                  >
                    ${sheet?.shareId ? html`<scribbl-sheet-share
                          slot="header"
                          .shareId=${sheet?.shareId}
                          .title=${sheet?.title}
                        ></scribbl-sheet-share>` : ''}
                    ${collapsed[DrawerSectionLabel.File] ? '' : html`
                          <scribbl-sheet-file
                            .shareId=${sheet?.shareId}
                            @toggle-share=${setSheetSharing}
                            @download-document=${downloadDocument}
                            @download-svg=${downloadSvg}
                            @import-document=${importDocument}
                          >
                          </scribbl-sheet-file>
                        `}
                  </scribbl-drawer-section>
                  <scribbl-drawer-section
                    label=${DrawerSectionLabel.Preview}
                    ?collapsed=${collapsed[DrawerSectionLabel.Preview]}
                    @toggle=${toggleCollapsed}
                  >
                    <scribbl-generate-preview slot="header" @generate=${generatePreview}></scribbl-generate-preview>
                    ${collapsed[DrawerSectionLabel.Preview] ? '' : html` <scribbl-image-preview .src=${sheet?.preview}></scribbl-image-preview>`}
                  </scribbl-drawer-section>
                </scrollable-component>
              ` : ''}
        </scribbl-drawer>
        <div ref=${forwardRef(stageRef)}>
          <canvas
            ref=${forwardRef(canvasRef)}
            @touchstart=${onTouchStart}
            @touchmove=${onTouchMove}
            @touchend=${onTouchEnd}
            @mousedown=${onMouseDown}
            @mousemove=${onMouseMove}
            @mouseup=${onMouseUp}
          ></canvas>
        </div>
      </tool-picker-provider>
    </color-picker-provider>
  `;
}

customElements.define('scribbl-artboard', component(ScribblArtboard));