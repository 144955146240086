export class Camera {
  zoom = 1;
  viewport = {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0
  };

  constructor(viewport, zoom = 1) {
    this.viewport = viewport;
    this.zoom = zoom;
  }

  get width() {
    return Math.abs(this.viewport.x1 - this.viewport.x2) * window.devicePixelRatio * this.zoom;
  }

  get height() {
    return Math.abs(this.viewport.y1 - this.viewport.y2) * window.devicePixelRatio * this.zoom;
  }

  panY(y) {
    this.viewport.y1 += y;
    this.viewport.y2 += y;
  }

  panX(x) {
    this.viewport.x1 += x;
    this.viewport.x2 += x;
  }

  zoomIn(step = 2) {
    this.zoom += step;
  }

  zoomOut(step = 2) {
    this.zoom -= step;
  }

}